import { Steps, Tooltip } from 'antd';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { breakName, convertDateToTimezone, convertTimeToTimezone, formatDateTo, getUData, getUserData } from '../../helpers/functions';
import { useNavigate } from 'react-router-dom';
import { nameRecommendationConstantForToolTip } from '../../helpers/constant';
import momenttimezone from 'moment-timezone';
export const ProcessHistoryStepsCard = props => {
  const { Step } = Steps;
  const navigate = useNavigate();
  const { rawData, showAssignedExpert, questionStatus } = props;
  const filteredProgressHistory =
    questionStatus?.length &&
    Object?.values(
      questionStatus?.reduce((data, itemIndex) => {
        data[itemIndex?.name] = itemIndex;
        return data;
      }, {}),
    );
 
  const userTimezone = getUserData()?.locations[0]?.timezone;
  return (
    <div className="request-steps-container">
      <div className="request-submitted">
        <Steps
          direction="vertical"
          size="default"
          responsive
          progressDot={true}
          current={questionStatus && questionStatus?.length - 1}
        >
          {filteredProgressHistory &&
            filteredProgressHistory?.map((el, index) => {
              return (
                <Step
                  key={index}
                  style={{
                    minHeight:
                      showAssignedExpert && el?.id === 3 && index === 2
                        ? 'inherit'
                        : el?.id === 12 && showAssignedExpert
                          ? '130px'
                          : '65px',
                  }}
                  title={
                    <div
                      style={{ fontSize: el['name'].length > 16 ? '15px' : '16px' }}
                      className="request-submitted-steps-header"
                    >
                      {el['name']}
              
                     
                      <div className="assigned-to-profile-role">
                      {convertDateToTimezone(el?.created_at)}
                      <br />
                      {convertTimeToTimezone(el?.created_at)}
                      </div>
                      {showAssignedExpert && (el?.id === 3 || (el?.id === 12 && index === 2)) && (
                        <div
                          className="request-submitted-steps-expert-card-wrapper"
                          onClick={() => navigate(`/find-expert-detail/${rawData?.expert?.id}`)}
                        >
                          <div className="request-submitted-steps-expert-card-container">
                            <div className="assigned-to-profile-icon">
                              <img
                                style={{ borderRadius: '50%' }}
                                alt="expert"
                                height={30}
                                width={30}
                                src={
                                  rawData?.expert_meta?.avatar
                                    ? rawData?.expert_meta?.avatar
                                    : expertIcon
                                }
                              />
                            </div>
                            <div className="assigned-to-profile-detail">
                              <div className="assigned-to-profile-name">

                                {

                                  (rawData?.status?.question_status?.status_order >= 7)
                                    ?
                                    rawData?.expert?.first_name + ' ' + rawData?.expert?.last_name
                                    :
                                    <Tooltip title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                                      {breakName(rawData?.expert?.first_name, rawData?.expert?.last_name)}
                                    </Tooltip>
                                }
                              </div>
                              <div className="assigned-to-profile-role">
                                {rawData?.category?.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                />
              );
            })}
        </Steps>
      </div>
    </div>
  );
};
