import React, { useState } from 'react';
import { Radio, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ExpertRequestCard from '../common/expertRequestCard';
import { MessagingContainer } from '../common/messagingContainer';
import ReactLinkify from 'react-linkify';
import { AdjustIconForNavigation } from '../../assets/images/components/AdjustIconForNavigation';
import { ProfileIconForNavigation } from '../../assets/images/components/ProfileIconForNavigation';
import { ArchiveSection } from '../customer/archiveSection';
import { ArchiveIconForNavigation } from '../../assets/images/components/ArchiveForNavigation';
import { getSubCategoryName } from '../../helpers/functions';

export const ExpertNewRequestDetail = () => {
  const { isCreateNewThreadLoading, isAcceptOrRejectExpertRequestLoading } = useSelector(
    state => state.requests,
  );

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { questionDetail, isQuestionLoading } = useSelector(state => state.common);
  const { qid } = useParams();
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const [selectedTab, setSelectedTab] = useState('request');

  useEffect(() => {
    if (qid) {
      dispatch({ type: 'GET_QUESTION_DETAIL', id: qid });
    }
  }, [isCreateNewThreadLoading, isAcceptOrRejectExpertRequestLoading]);

  const isStatusAssigned = id => {
    if (id === 3 || id === 10) {
      return true;
    }
    return false;
  };

  const onAcceptNewRequest = (id, type) => {
    dispatch({
      type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: id, accepted: 1 },
    });
    setTimeout(() => {
      window.location.href = '/dashboard/expert/request';
    }, 2000);
  };

  const renderUsingSwitch = () => {
    switch (selectedTab) {
      case 'request':
        return (
          <div className="request-details">
            <h3>Request Details</h3>
            <div className="request-details-wrapper">
              <>
                <div>
                  <div style={{ maxWidth: '100%' }} className="general">
                    <ReactLinkify>{questionDetail?.question_disc}</ReactLinkify>
                  </div>
                </div>
              </>
              <div className="request-group">
                <div>
                  <h6>I need to contact an expert for a</h6>
                  <p>
                    {questionDetail?.question_type?.name
                      ? questionDetail?.question_type?.name
                      : '-'}
                  </p>
                </div>
                <div>
                  <h6>I am (a/an) or representing (a/an)</h6>
                  <p>
                    {questionDetail?.customer_type?.name
                      ? questionDetail?.customer_type?.name
                      : '-'}
                  </p>
                </div>
                <div>
                  <h6>Service category</h6>
                  <p>{questionDetail?.category?.name ? questionDetail?.category?.name : '-'}</p>
                </div>
                <div>
                  <h6>
                    {questionDetail?.category?.name
                      ? questionDetail?.category?.name + ' Subcategory'
                      : '-'}
                  </h6>
                  <p>{getSubCategoryName(questionDetail)}</p>
                </div>
                <div>
                  <h6>Business Sector</h6>
                  <p>{questionDetail?.sector?.name ? questionDetail?.sector?.name : '-'}</p>
                </div>
                <div>
                  <h6>How many invoices do you handle per month?</h6>
                  <p>{questionDetail?.invoices?.name ? questionDetail?.invoices?.name : '-'}</p>
                </div>
                <div>
                  <h6>Ownership type</h6>
                  <p>{questionDetail?.ownership?.name ? questionDetail?.ownership?.name : '-'}</p>
                </div>
                <div>
                  <h6>Location</h6>
                  <p>{questionDetail?.location?.name ? questionDetail?.location?.name : '-'}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'messaging':
        return (
          <div className="request-message-details-with-border">
            <MessagingContainer
              subjectId={qid}
              expertId={questionDetail?.user?.id}
              type="newDetail"
            />
          </div>
        );
      case 'archive':
        return (
          <div className="archive-body">
            <ArchiveSection subjectId={qid} />
          </div>
        );
      default:
        return;
    }
  };

  return !isQuestionLoading ? (
    <div className="request-details-body">
      <ExpertRequestCard
        {...{ ...state, setSelectedTab }}
        isClickable={false}
        type="NewRequest"
        navigateBack={true}
        onAcceptRequestClick={() => onAcceptNewRequest(state.id, 'request')}
      />

      <div className="request">
        <div style={{ width: '100%' }} className="request-wrapper">
          {isStatusAssigned(questionDetail?.status?.question_status_id) && (
            <>
              <div>
                <Radio.Group
                  className="request-tabs-navigator-container"
                  defaultValue={selectedTab}
                  buttonStyle="solid"
                  onChange={e => setSelectedTab(e.target.value)}
                  value={selectedTab}
                >
                  <Radio.Button className="request-tabs-navigator-tabs" value="request">
                    <div className="request-tabs-navigator-tabs-div">
                      <ProfileIconForNavigation isActive={selectedTab === 'request'} />
                      Request Details
                    </div>
                  </Radio.Button>
                  <Radio.Button className="request-tabs-navigator-tabs" value="messaging">
                    <div className="request-tabs-navigator-tabs-div">
                      <AdjustIconForNavigation isActive={selectedTab === 'messaging'} />
                      Messaging
                    </div>
                  </Radio.Button>
                  <Radio.Button className="request-tabs-navigator-tabs" value="archive">
                    <div className="request-tabs-navigator-tabs-div">
                      <ArchiveIconForNavigation isActive={selectedTab === 'archive'} />
                      Archive
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </div>
            </>
          )}
          <div className="request-container">{renderUsingSwitch()}</div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ height: (height * 60) / 100 }} className="d-flex-a-c">
      <Spin />
    </div>
  );
};
