import { Button, Col, Row, Progress, message } from 'antd';
import React, { useState } from 'react';
import { convertDateToTimezone, convertTimeToTimezone, formatDateTo, getSubCategoryName } from '../../helpers/functions';
import { ReviewModal } from './modal/reviewModal';

const RequestHistoryCard = props => {
  const [showModal, setShowModal] = useState(false);

  const showReviewModal = () => {
    if (!props?.question_review?.length) {
      setShowModal(true);
    } else {
      message.error(`Review for request id #${props?.id} is already registered.`);
    }
  };

  return (
    <div className={`requests-history-accounting ${props?.list ? 'custom-request-card' : ""}`} onClick={props?.onRequestClick} >
      <Row className="requests-history-accounting-row">
        <Col
          className="requests-accounting-col"
          onClick={props.isClickable ? props.onRequestClick : undefined}
          style={{ cursor: props.isClickable && 'pointer' }}
          span={3}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img
              src={props.expert_meta?.avatar}
              alt=" img"
              height={'60px'}
              width={'60px'}
              style={{ borderRadius: '50%' }}
            />
              <div style={{ marginTop: '10px',fontWeight: 'bold' }}>
              {props?.expert?.first_name + ' ' + props?.expert?.last_name}
              </div>
           
          </div>
        </Col>
        <Col
          onClick={props.isClickable ? props.onRequestClick : undefined}
          className="requests-history-accounting-col"
          style={{ cursor: 'pointer' }}
          span={5}
        >
          <div className="id pr-4">
            <div className="p-title">ID #{props?.id ? props?.id : '-'}</div>
            <h3 className="title">{props?.category?.name ? props?.category?.name : '-'}</h3>
            <p className="title-i">
              <i>{getSubCategoryName(props)}</i>
            </p>
          </div>
        </Col>
        <Col span={3} className="requests-history-accounting-col">
          <div className="date">
            <div className="p-title">Request date</div>
            <h3 className="title-date title">{convertDateToTimezone(props?.created_at)}</h3>
            <h3 className="title-date title">{convertTimeToTimezone(props?.created_at)}</h3>
          </div>
        </Col>
        <Col span={3} className="requests-history-accounting-col">
          <div className="date">
            <div className="p-title">Started on</div>
            <h3 className="title-date title">
                {props?.status?.created_at
                  ? <>{convertDateToTimezone(props?.status?.created_at)} <br /> {convertTimeToTimezone(props?.status?.created_at)}</> 
                  : '-'}
              </h3>
          </div>
        </Col>
        <Col span={6} className="requests-history-accounting-col">
          <div className="status">
            <div className="">
              <div className="p-title">Request status</div>
              <div className="requests-history-accounting-date-container">
                <h3
                  style={{
                    color: props?.status?.question_status_id === 10 ? '#F05252' : '#00AA55',
                  }}
                  className="title"
                >
                  {props?.status?.question_status?.name}
                </h3>
                <p className="title-date-p">
                <>{convertDateToTimezone(props?.status?.created_at)} <br /> {convertTimeToTimezone(props?.status?.created_at)}</>
                </p>
              </div>
              <div className="progress-history-card flex-d-a-c">
                <Progress
                  type="line"
                  percent={100}
                  showInfo={false}
                  success={{
                    percent:
                      props?.status?.question_status_id !== 10
                        ? props?.status?.question_status_id
                          ? props?.status?.question_status_id === 12
                            ? 20
                            : props?.status?.question_status_id * 10
                          : 0
                        : 90,
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        {!props?.list && <Col className="requests-history-accounting-col" span={2}>
          <div className="f-flex-a-e expert-request-button-group">
            {/* {props.isClickable && (
              <Button className="expert-request-button" onClick={props.onRequestClick}>
                View Details
              </Button>
            )} */}
            <Button className="expert-request-button" onClick={showReviewModal} type="primary">
              Mark a review
            </Button>
          </div>
        </Col>}
      </Row>

      <ReviewModal
        visible={showModal}
        setShowModal={setShowModal}
        expertId={props?.expert?.id}
        questionId={props?.id}
        questionStatus={props?.status?.id > 9 ? 'deactive' : 'active'}
        rawData={props}
      />
    </div>
  );
};

export default RequestHistoryCard;
