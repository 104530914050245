import { Button, Col, Row, Progress, Tooltip, Popover } from 'antd';
import React from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { breakName, convertDateToTimezone, convertTimeToTimezone, formatDateTo, getSubCategoryName } from '../../helpers/functions';
import { nameRecommendationConstantForToolTip } from '../../helpers/constant';

const RequestCard = props => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestCancel = () => {
    dispatch({
      type: 'UPDATE_QUESTION_STATUS',
      formData: { question_id: props?.id, question_status_id: 10 },
    });
    if (props?.navigateBackOnCancel) {
      navigate('/dashboard/customer');
    }
  };

  const handleCompleteRequest = () => {
    navigate(`/request/${props?.id}?type=customer-type`);
  };

  const isRequestPending = () => {
    if (props?.request_submitted === 0) {
      return true;
    } else if (props?.request_submitted === 1) {
      return false;
    }
  };
  // console.log(findExpertAvatar(),props,expertList,common)
  const popoverContent = (
    <div className="status-popover-content-container">
      <div onClick={handleRequestCancel} className="status-popover-cancel-button">
        Cancel Request
      </div>
    </div>
  );


  return (
    <div
      className={`requests-history-accounting ${props?.list ? 'custom-request-card' : ""}`}
      onClick={props?.onRequestClick}
    >
      <Row className="requests-accounting-row">
        <Col
          className="requests-accounting-col"
          onClick={props.isClickable ? props.onRequestClick : undefined}
          style={{ cursor: props.isClickable && 'pointer' }}
          span={3}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img
              src={props.expert_meta?.avatar}
              alt=""
              height={'60px'}
              width={'60px'}
              style={{ borderRadius: '50%' }}
            />
            <div style={{ marginTop: '20px',fontWeight: 'bold' }}>
            {
              (props?.status?.question_status?.status_order >= 7)
                ?
                props?.expert?.first_name + ' ' + props?.expert?.last_name
                :
                <Tooltip  title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                  {breakName(props?.expert?.first_name, props?.expert?.last_name)}
                </Tooltip>
            }
            </div>
           
          </div>
        </Col>
        <Col span={6}>
          <div className="id pr-4">
            <div className="p-title">ID #{props.id}</div>
            <h3 className="title">{props?.category?.name ? props?.category?.name : '-'}</h3>
            <p className="title-i">
              <i>{getSubCategoryName(props)}</i>
            </p>
          </div>
        </Col>
        <Col span={6}>
          <div className="date">
            <div className="p-title">Request date</div>
            <h3 className="title-date title">{convertDateToTimezone(props?.created_at)}</h3>
            <h3 className="title-date title">{convertTimeToTimezone(props?.created_at)}</h3>
          </div>
        </Col>
        <Col className="requests-accounting-col" span={!props?.list ? 3 : 6}>
          <div className="status">
            <div>
              <div className="p-title">Request status</div>
              <h3
                style={{
                  color:
                    props?.status?.question_status_id === 10 || isRequestPending()
                      ? '#F05252'
                      : undefined,
                }}
                className="title"
              >
                {isRequestPending()
                  ? 'Request Pending'
                  : props?.status?.question_status?.name
                    ? props?.status?.question_status?.name
                    : 'No Status'}
              </h3>
              <div className="progress-request-card flex-d-a-c">
                {/* 3 done / 3 in progress / 4 to do */}
                <Tooltip
                  title={`${props?.status?.question_status_id ? (props?.status?.question_status_id === 12 ? '20%' : `${props?.status?.question_status_id * 10}%`) : '0 done / 10 to do'}`}
                >
                  <Progress
                    type="line"
                    percent={100}
                    showInfo={false}
                    success={{
                      percent: isRequestPending()
                        ? 0
                        : props?.status?.question_status_id
                          ? props?.status?.question_status_id === 12
                            ? 20
                            : props?.status?.question_status_id * 10
                          : 0,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </Col>
        {!props?.list && (
          <Col className="requests-accounting-col" span={3}>
            <div className="f-flex-a-e expert-request-button-group">
              {!props.isClickable && isRequestPending() && (
                <Button
                  style={{ width: '160px' }}
                  className="expert-request-button"
                  onClick={handleCompleteRequest}
                  type="primary"
                >
                  Complete request
                </Button>
              )}
              {/* {props.isClickable && (
              <Button className="expert-request-button" onClick={props.onRequestClick}>
                View details
              </Button>
            )} */}
              {!props?.list && props?.status?.question_status_id < 10 && (
                <Popover
                  placement="bottomLeft"
                  content={popoverContent}
                  trigger="click"
                  showArrow={false}
                >
                  <div className="status-popover-option-icon">
                    <HiOutlineDotsVertical size={32} />
                  </div>
                </Popover>
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RequestCard;
