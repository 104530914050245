import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Radio, Spin, Tag, Upload, message } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { useNavigate } from 'react-router-dom';
import BlogTags from './Tags';
const CreateBlog = () => {
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const isCreateBlogLoading = useSelector(state => state?.common?.isCreateBlogLoading);
  const navigate = useNavigate();
  const handleImageUpload = ({ fileList }) => {
    setFileList(fileList);
    const file = fileList[0];
    if (file) {
      setImage(file.originFileObj); // Set the image file
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = values => {
    if (content === '') {
      message.error('Please provide body');
      return;
    }
    if (!image) {
      message.error('Please provide image');
      return;
    }
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('body', content);
    if (image) {
      formData.append('featured_image', image);
    }
    formData.append('published', values.published);
    formData.append('slug', values.title + Date.now());
    formData.append('author_id', 1);
    formData.append('excerpt', 'excerpt');
    formData.append('featured_image_caption', values.featured_image_caption);
    formData.append('tags', JSON.stringify(tags));
    dispatch({ type: 'CREATE_BLOG', formData: formData });
    setLoading(true);
  };
  useEffect(() => {
    if (!isCreateBlogLoading && loading) {
      setLoading(false);
      form.resetFields();
      setContent('');
      setImage(null);
      setFileList([]);
      setTags([]);
      message.success('Blog created successfully');
      dispatch({ type: 'CREATE_BLOG_ERROR' });
      navigate('/admin/blogs', { state: '' });
    }
  }, [isCreateBlogLoading]);
  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="horizontal" style={{ maxWidth: 900 }}>
        <Form.Item
          label="Title"
          name="title" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <EditorProvider>
          <Editor
            containerProps={{ style: { height: 300, resize: 'vertical' } }}
            value={content}
            onChange={e => setContent(e.target.value)}
          >
            <Toolbar>
              <BtnBold />
              <BtnItalic />
            </Toolbar>
          </Editor>
        </EditorProvider>
        <Form.Item label="Published" name="published" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item label="Upload Image">
          <Upload
            name="image"
            onChange={handleImageUpload}
            fileList={fileList}
            maxCount={1}
            listType="picture-card"
          >
            {fileList.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Featured Image Caption"
          name="featured_image_caption" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Featured Image Caption',
            },
          ]}
        >
          <Input placeholder="Featured Image Caption" />
        </Form.Item>
        <BlogTags tags={tags} setTags={setTags} />
        <Form.Item>
          {
          loading ? (
            <Spin />
          ) : (
            <Button type="primary" htmlType="submit" className="w100 mt25" size="large">
              Create
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateBlog;
