import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Popover, Avatar, Badge } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import WhiteLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import {
  dynamicUnreadMessageCountContainer,
  formatNotification,
  getDate,
  getFullName,
  getUserData,
  handleNotification,
  isLogin,
  logout,
  showAvatarName,
  truncateString,
} from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { BellTwoTone } from '@ant-design/icons';
const { Header } = Layout;

const items = [
  {
    label: 'My Requests',
    key: 'dashboard/expert/request',
  },
  {
    label: 'History',
    key: 'dashboard/expert/history',
  },
  {
    label: 'Messages',
    key: 'dashboard/expert/messages',
  },
];

const ExpertDashboardHeader = ({ role = '' }) => {
  const notifications = useSelector(state => state?.common?.notifications);
  const newNotificationCount = useSelector(state => state?.common?.newNotificationCount);
  const dispatch = useDispatch();
  const { expertDetailById, isExpertDetailLoading, unReadMessagesRec } = useSelector(
    state => state.requests,
  );
  const [unreadMessages, setUnreadMessages] = useState(0);
  // hide header when print mode is on
  const { print } = useParams();
  useEffect(() => {
    if (isLogin()) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: getUserData().id });
    }
  }, []);

  useEffect(() => {
    if (expertDetailById) {
      localStorage.setItem('expertAvatar', expertDetailById?.avatar);
    }
  }, [isExpertDetailLoading]);

  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState('/expert/request');

  const onClick = e => {
    if (
      e.key === 'dashboard/expert/request' ||
      e.key === 'dashboard/expert/history' ||
      e.key === 'dashboard/expert/messages'
    ) {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/login?type=expert');
  };

  const onProfileClick = () => {
    navigate('/dashboard/expert/detail');
  };
  const content = (
    <div className="status-popover-inner-container" style={{ userSelect: 'none' }}>
      <p>{getFullName()}</p>
      <p>
        <div
          style={{ cursor: 'pointer' }}
          className="status-popover-cancel-button"
          onClick={() => onProfileClick()}
        >
          Profile
        </div>
      </p>
      <p>
        <div
          style={{ cursor: 'pointer' }}
          className="status-popover-cancel-button"
          onClick={() => onLogoutClick()}
        >
          Logout
        </div>
      </p>
    </div>
  );

  useEffect(() => {
    if (notifications?.length === 0) {
      dispatch({ type: 'GET_NOTIFICATION' });
    }
  }, []);

  function handleNotificationClick(n) {
    if (newNotificationCount > 0) {
      dispatch({ type: 'REMOVE_NOTIFICATION_COUNT_SUCCESS' });
    }
    if (typeof n?.data === 'string') {
      console.log('JSON.parse(n?.data): ', handleNotification(JSON.parse(n?.data)?.type, n));

      navigate(
        handleNotification(JSON.parse(n?.data)?.type, n) + (JSON.parse(n?.data)?.redirect_id || ''), { state: { "from": 'notification', } }
      );
    } else if (n?.data) {
      navigate(
        handleNotification(JSON.parse(n?.data[0])?.type, n) + (JSON.parse(n?.data[0])?.redirect_id || ''), { state: { "from": 'notification' } }
      );
    }
  }
  // ant-popover-content
  const notificationContent = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center  ', width: '100%' }}>
      {notifications?.length ? (
        notifications.slice(0, 3).map((n, i) => {
          return (
            <div
              onClick={() => handleNotificationClick(n)}
              key={i}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexDirection: 'column',
                backgroundColor: `${newNotificationCount > 0 && i < newNotificationCount ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.05)'}`,
                marginBottom: '1rem',
                padding: '.5rem',
                borderRadius: '5px',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>{formatNotification(n)[0]}</p>
              <p style={{ fontSize: '12px' }}>{truncateString(formatNotification(n)[1])}</p>
              <p style={{ fontSize: '12px' }}>{getDate(n)}</p>
            </div>
          );
        })
      ) : (
        <p>No new notifications</p>
      )}
      <p style={{ cursor: 'pointer', color: 'blue' }} onClick={() => navigate('/notifications')}>
        View more
      </p>
    </div>
  );

  useEffect(() => {
    if (unReadMessagesRec && unReadMessagesRec.length > 0) {
      setUnreadMessages(() =>
        unReadMessagesRec.reduce((n, { unreadMessageCount }) => n + unreadMessageCount, 0),
      );
    } else {
      setUnreadMessages(0);
    }
  }, [unReadMessagesRec]);

  useEffect(() => {
    if (isLogin()) {
      dispatch({ type: 'GET_ALL_UNREAD_MESSAGES' });
    }
  }, []);

  return (
    <>
      {!!print || (
        <Header className={`header ${location.pathname === '/expert' ? 'transfer' : ''}`}>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }} gutter={30}>
            <div className="main-logo" style={{
              width: '300px',
            }}>
              {location.pathname === '/expert' ? (
                <img
                  src={WhiteLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer w100"
                  alt="Expert Logo "
                ></img>
              ) : (
                <img
                  src={MainLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer w100"
                  alt="Expert Logo "
                ></img>
              )}
            </div>
            <div className="header-menu public-header d-flex-a-c">
              <div style={{ gap: '15px' }} className="d-flex-a-c">
                {items.map((item, index) => {
                  return (
                    <>
                      <Badge count={item.label == 'Messages' ? unreadMessages : 0} color="#1C64F2">
                        <p key={index} className="mb0" onClick={e => onClick(item)}>
                          <span
                            style={{ color: current === item.key && '#1C64F2' }}
                            className="cursor-p"
                          >
                            {item.label}
                          </span>
                        </p>
                      </Badge>
                    </>
                  );
                })}
              </div>
            </div>
            <div style={{ display: 'flex', gap: 10, position: 'relative' }}>
              {isLogin() && (
                <>
                  <Popover trigger={'click'} content={notificationContent} title="Notifications" onClick={() => dispatch({ type: 'READ_NOTIFICATION' })}>
                    <span className="notification_bell">
                      {' '}
                      <BellTwoTone />
                    </span>
                    {newNotificationCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '15%',
                          right: '60%',
                          zIndex: '1',
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ fontSize: '12px' }}>{newNotificationCount}</span>
                      </div>
                    )}
                  </Popover>
                  <div className="f-flex-a-e">
                    <div className="header-menu d-flex-a-center">
                      <Popover placement="bottom" content={content} trigger="hover">
                        {expertDetailById?.avatar ? (
                          <img
                            style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                            src={expertDetailById?.avatar}
                            alt=""
                          />
                        ) : (
                          <Avatar size="large" className="pointer">
                            {showAvatarName()}
                          </Avatar>
                        )}
                      </Popover>
                    </div>
                  </div>
                </>
              )}
              {!isLogin() && (
                <div className="d-flex-a-c">
                  <p className="mb0 mr15">Client Login</p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="float-r"
                    size="middle"
                    onClick={() => navigate('/login?type=expert')}
                  >
                    Become an Expert
                  </Button>
                </div>
              )}
            </div>
          </Row>
        </Header>
      )}
    </>
  );
};

export default ExpertDashboardHeader;
