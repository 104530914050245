import React, { useEffect } from 'react';
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useParams,
} from 'react-router-dom'; 
import { Layout, ConfigProvider } from 'antd';
import { RoutesList } from './routes';
// Import Layouts
import Header from './components/layouts/header';
import PublicHeader from './components/layouts/publicHeader';
import Sidebar from './components/layouts/sidebar';
import { useLocation } from 'react-router-dom';
import { getUserData, isLogin, role } from './helpers/functions';
import { pusher, ROLE_NAME } from './helpers/constant';
import PublicFooter from './components/layouts/publicFooter';
/** Import the style **/
import './index.scss';
import ExpertHeader from './components/layouts/expertHeader';
import CustomerHeader from './components/layouts/customerHeader';
import ExpertDashboardHeader from './components/layouts/expertDashboardHeader';
import { useDispatch } from 'react-redux';
import { usePusher } from './components/common/PusherContext';

import notificationSound from './assets/audio/notification.mp3';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import DialogueFlow from './components/common/dialogueFlow';


const { Content, Footer } = Layout;

function App() {
  const dispatch = useDispatch();
  const { channel, echo } = usePusher();
  const PublicRoutes = props => {
    const location = useLocation();
    if (props.type === 0) {
      return <Layout>{props.children}</Layout>;
    } else {

      if (isLogin() && role() === ROLE_NAME.ADMIN) {
        return (
          <Layout className="main-layout admin-dashboard">
            <Header />
            <Layout>
              {props.type !== 5 && <Sidebar />}
              <Layout
                style={{
                  padding: '0 24px 24px',
                }}
                className="content-layout"
              >
                <Content
                  className="site-layout-background admin-content"
                  style={{
                    padding: '20px 15px',
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  {props.children}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        );
      } else {
        return (
          <Layout className="main-layout">
            {/* <DialogueFlow /> */}
            <PublicHeader />
            <Layout className="content-layout public">
              <Content className="site-layout-background">{props.children}</Content>
            </Layout>
            {location.pathname !== '/contact' && <PublicFooter />}
          </Layout>
        );
      }
    }
  };

  const ProtectedRoute = props => {
    // hide header/footer in print mode
    const { print } = useParams();
    const location = useLocation();
    if (!isLogin()) {
      if (props.steps !== undefined && props.steps && props.signup !== undefined && props.signup) {
        return (
          <Layout className="main-layout">
            <Layout className={`content-layout`}>
              <Content className="site-layout-background">{props.children}</Content>
            </Layout>
          </Layout>
        );
      } else {
        return <Navigate to="/login" replace />;
      }
    } else {
      if (isLogin() && role() === ROLE_NAME.ADMIN) {
        return (
          <Layout className="main-layout admin-dashboard">
            <Header />
            <Layout>
              {props.type !== 5 && <Sidebar />}
              <Layout
                style={{
                  padding: '0 24px 24px',
                }}
                className="content-layout"
              >
                <Content
                  className="site-layout-background admin-content"
                  style={{
                    padding: '20px 15px',
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  {props.children}
                </Content>
              </Layout>
            </Layout>
            {/* <Version /> */}
          </Layout>
        );
      } else if (props.steps !== undefined && props.steps) {
        return (
          <Layout className="main-layout">
            <Layout className={`content-layout`}>
              <Content className="site-layout-background">{props.children}</Content>
            </Layout>
          </Layout>
        );
      } else {
        return (
          <Layout className="main-layout" style={{ minWidth: 'auto' }}>
            {role() !== ROLE_NAME.EXPERT && role() !== ROLE_NAME.CUSTOMER && <Header />}
            {role() === ROLE_NAME.CUSTOMER && <CustomerHeader />}
            {role() === ROLE_NAME.EXPERT && location.pathname.includes('/dashboard/expert') && (
              <ExpertDashboardHeader />
            )}
            {role() === ROLE_NAME.EXPERT && !location.pathname.includes('/dashboard/expert') && (
              <ExpertHeader />
            )}
            <Layout
              className={`content-layout ${location.pathname !== '/expert' ? 'dashboard ' : ' '} ${role() === ROLE_NAME.EXPERT ? 'public' : ''}`}
            >
              <Content className="site-layout-background">{props.children}</Content>
            </Layout>
            {role() === ROLE_NAME.EXPERT &&
              (location.pathname === '/expert' || location.pathname === '/expert/faq') && (
                <PublicFooter />
              )}
            {location.pathname !== '/expert' ||
              (print && (
                <Footer className="dashboard-footer">
                  © 2023 accountizer.ca - All rights reserved.
                </Footer>
              ))}
          </Layout>
        );
      }
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {RoutesList.map((item, index) => {
          if (item.type === 0 || item.type === 1) {
            return (
              <Route
                key={item.type}
                path={item.path}
                element={<PublicRoutes {...item}>{item.element}</PublicRoutes>}
              >

              </Route>
            );
          } else {
            return (
              <Route
                key={item.type}
                path={item.path}
                element={
                  <ProtectedRoute {...item} allowed={item.role}>
                    {item.element}
                  </ProtectedRoute>
                }
              ></Route>
            );
          }
        })}
      </Route>,
    ),
  );

  // pusher implemenation here
  useEffect(() => {
    if (channel) {
      const handlePusherUnreadMessageEvent = data => {
        dispatch({ type: 'ADD_NEW_UNREAD_MESSAGE', data: data.message });
        if (!document.getElementById("message-body")) {
          const audio = new Audio(notificationSound);
          audio.play();
        }
      }
      channel.bind("unReadMessage", handlePusherUnreadMessageEvent);
      return () => {
        channel.unbind("unReadMessage", handlePusherUnreadMessageEvent);
      };
    }
  }, [channel]);


  // echo implementation here
  useEffect(() => {
    if (!echo) return;
    echo.channel(`${getUserData()?.id}`).notification((e) => {
      dispatch({ type: 'ADD_NEW_NOTIFICATION', data: [e[0]] });
      const audio = new Audio(notificationSound);
      audio.play();
    });
    return () => {
      echo.leave(`${getUserData()?.id}`);
    };
  }, [echo]);


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter, sans-serif',
          colorPrimary: '#1C64F2',
          colorLink: '#1C64F2',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
