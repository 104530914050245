import { Button, Checkbox, Modal } from 'antd'
import React from 'react'
import warningImg from '../../../assets/images/warningImg.jpeg';


export default function WarningModal({ showWarningModal, handleWarningModelGotIt, text, image, setDontShowAgain, showDontShowAgain, showLetsSeeIt, handleLetSeeItClick }) {
    return (
        <Modal
            title=" "
            open={showWarningModal}
            okText="Got it"
            closable={false}
            zIndex={10000}
            // width={"50%"}
            centered
            footer={
                <div>
                    <Button
                        type="primary"
                        size="middle"
                        hidden={!showLetsSeeIt}
                        className="mt5 mr5"
                        onClick={() => handleLetSeeItClick()}
                    >
                        Let&#39;s See It
                    </Button>
                    <Button
                        type="primary"
                        size="middle"
                        className="mt5"
                        onClick={() => handleWarningModelGotIt()}
                    >
                        Got it
                    </Button>

                </div>
            }
        >
            <div style={{ textAlign: 'center' }}>

                <img src={image || warningImg} width={150} style={{ borderRadius: '50%' }} alt="warning" />
                <p style={{ textAlign: 'left', marginTop: '20px', fontSize: '15px', }} dangerouslySetInnerHTML={{ __html: text }}>
                </p>
                {showDontShowAgain && <Checkbox
                    style={{ color: '#4472c4' }}
                    onChange={e => setDontShowAgain(e.target.checked)}
                >
                    Don’t show this message again
                </Checkbox>}
            </div>
        </Modal>
    )
}
