/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { createMarkup, parseProposal } from '../../../helpers/functions';
import Cookies from 'js-cookie';
import { useSpring, animated } from '@react-spring/web';
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const EvaluationReportModal = props => {
  const { visible, setVisible, questionDetail, handleWhatsNext } = props;
  const [fontSize, setFontSize] = useState(1.2);
  const [size, setSize] = useState({ width: 900, height: 600 });
  const [isResizing, setIsResizing] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { isDontShowWhatsNext } = useSelector(state => state.common);

  const styles = useSpring({
    width: size.width,
    height: size.height,
    config: { tension: 200, friction: 20 },
  });

  const handleMouseDown = direction => e => {
    e.preventDefault();
    setIsResizing(direction);
  };

  const handleMouseMove = e => {
    if (!isResizing) return;

    const modal = modalRef.current;
    const rect = modal.getBoundingClientRect();

    if (isResizing === 'top') {
      const newHeight = rect.bottom - e.clientY;
      if (newHeight >= 100) {
        setSize({ width: size.width, height: newHeight });
      }
    } else if (isResizing === 'bottom') {
      const newHeight = e.clientY - rect.top;
      if (newHeight >= 100) {
        setSize({ width: size.width, height: newHeight });
      }
    } else if (isResizing === 'left') {
      const newWidth = rect.right - e.clientX;
      if (newWidth >= 100) {
        setSize({ width: newWidth, height: size.height });
      }
    } else if (isResizing === 'right') {
      const newWidth = e.clientX - rect.left;
      if (newWidth >= 100) {
        setSize({ width: newWidth, height: size.height });
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  const onCancel = () => {
    setVisible(false);
    if (!Cookies.get(`dontShowWhatsNext_${questionDetail?.user?.id}`)) {
      dispatch({ type: 'GET_DONT_SHOW_WHATS_NEXT_MODAL', userId: questionDetail?.user?.id });
    }
    if (isDontShowWhatsNext) {
      Cookies.set(`dontShowWhatsNext_${questionDetail?.user?.id}`, 'true', { expires: 365 });
    }
    const dontShowWhatsNext = Cookies.get(`dontShowWhatsNext_${questionDetail?.user?.id}`);
    if (!dontShowWhatsNext) {
      handleWhatsNext(true);
    }
    Cookies.set(`dontShowWhatsNext_${questionDetail?.user?.id}_${questionDetail?.id}`, 'true', { expires: 365 });
  };

  const changeFontSize = changeType => {
    if (changeType === 'plus') {
      if (fontSize < 2.5) setFontSize(fontSize + 0.125);
    } else if (changeType === 'minus') {
      if (fontSize > 1) setFontSize(fontSize - 0.125);
    }
  };


  return (
    <Modal
      className={`evaluation-report-modal custom-modal`}
      open={visible}
      footer={false}
      centered
      width={''}
      // width={'100%'}
      // style={{ maxWidth: '900px' }}
      maskClosable={false}
      closeIcon={true}
      closable={true}
      onCancel={() => onCancel()}
      modalRender={modal => (
        <animated.div
          ref={modalRef}
          style={styles}
          className="custom-ant-evaluation-report-modal-content ant-modal-content "
        >
          {modal}
          <div className="resize-handle top" onMouseDown={handleMouseDown('top')}>
            <ArrowUpOutlined />
          </div>
          <div className="resize-handle bottom" onMouseDown={handleMouseDown('bottom')}>
            <ArrowDownOutlined />
          </div>
          <div className="resize-handle left" onMouseDown={handleMouseDown('left')}>
            <ArrowLeftOutlined />
          </div>
          <div className="resize-handle right" onMouseDown={handleMouseDown('right')}>
            <ArrowRightOutlined />
          </div>
        </animated.div>
      )}
    >
      <div className="mt5 mb5 pr5 pl5" style={{ fontSize: fontSize + 'rem' }}>
        <div className="evaluation-modal-header">
          <h3 style={{ fontSize: '1.024em' }}>
            Dear {questionDetail?.user?.first_name + ' ' + questionDetail?.user?.last_name},
          </h3>
          <div className="text-size-buttons d-flex justify-content-end">
            <div className="text-minus-button" onClick={() => changeFontSize('minus')}>
              <MinusCircleOutlined
                style={{
                  color: fontSize <= 1 ? 'rgb(128, 128, 128)' : 'rgb(28, 119, 255)',
                  background:
                    fontSize <= 1 ? 'rgba(185, 185, 185, 0.3)' : 'rgba(164, 194, 253, 0.3)',
                  borderRadius: '50%',
                }}
              />
            </div>
            <div className="text-plus-button" onClick={() => changeFontSize('plus')}>
              <PlusCircleOutlined
                style={{
                  color: fontSize >= 2.5 ? 'rgb(128, 128, 128)' : 'rgb(28, 119, 255)',
                  background:
                    fontSize >= 2.5 ? 'rgba(185, 185, 185, 0.3)' : 'rgba(164, 194, 253, 0.3)',
                  borderRadius: '50%',
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="evaluation-modal-inner-text"
          style={{ fontSize: '0.875em', height: size.height - 85 }}
        >
          <center>
            <span
              style={{ textAlign: 'start' }}
              dangerouslySetInnerHTML={createMarkup(parseProposal(questionDetail?.proposal ?? '', questionDetail?.expert?.first_name, questionDetail?.expert?.last_name, questionDetail?.status?.question_status?.status_order ?? 0))}
            />
          </center>
          <p>Best Regards,</p>
          <p>The Accountizer Team</p>
        </div>
      </div>
    </Modal>
  );
};

export default EvaluationReportModal;
