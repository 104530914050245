import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Card, Tooltip, Input, Flex } from 'antd';
import Helmet from '../../components/common/helmet';
import ReviewCard from '../../components/common/reviewCard';
import client_home_thumbnail from '../../assets/images/client_home_video_thumbnail.png';

import { Modal } from 'antd';
import TextIcon from '../../assets/images/text-icon.svg';
import howItWork from '../../assets/images/howItWork.png';
import howItWork2 from '../../assets/images/howItWork2.png';
import howItWork3 from '../../assets/images/howItWork3.png';
import howItWork4 from '../../assets/images/howItWork4.png';
import videoCameraIcon from '../../assets/images/video-camera-icon.png';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CUSTOMER_REVIEW, ROLE_NAME, step_Constants } from '../../helpers/constant';
import { getUserData, isLogin, role } from '../../helpers/functions';
import ReactPlayer from 'react-player';
import TextArea from 'antd/es/input/TextArea';
import { ExpertTermsModal } from '../common/modal/expertTermsModal';

// import typerwriter for text animation
import Typewriter from 'typewriter-effect';
import { useDispatch, useSelector } from 'react-redux';
import { Capsule } from '../common';

// imags imported here
// imags imported here
import Business from '../../assets/images/questions_icons/business.png';
import IndividualLogo from '../../assets/images/questions_icons/individual.png';
import calculaterLogo from '../../assets/images/questions_icons/calculator.png';
import SearchLogo from '../../assets/images/questions_icons/search.png';

// import react-spring/web
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import WarningModal from '../common/modal/WarningModal';
import editPencilImg from "../../assets/images/pencilImage.png";
import Cookies from 'js-cookie';


const HomePage = props => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  // -------------------------------//
  let {
    isInvoiceLoading,
    invoiceList,
    isBSLoading,
    businessTypeCategoryList,
    isLocationLoading,
    locationList,
    isSCLoading,
    serviceCategoryList,
    isLangLoading,
    languageList,
  } = useSelector(state => state.common);
  let {
    subCatRecords,
    subCatGetRecordsMessage,
    fullQuestionCreateMsg,
    questionGenerated,
    genratedQuestionId,
  } = useSelector(state => state.steps);
  // ------------------ use selector for redux are here -----------------
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);
  const [question, setQuestion] = useState('');
  const [typeWriterDisplayText, setTypeWriterDisplayText] = useState('');
  const [step, setStep] = useState(step_Constants.STEP_1.type);
  const [capsulViewRecord, setCapsulViewRecord] = useState([]);
  const [localData, setLocalData] = useState({});
  const [laterBtnVisible, setLaterBtnVisible] = useState(false);
  const [signupTextVisible, setSignupTextVisible] = useState(false);
  const [subCatQuestionText, setSubCatQuestionText] = useState('');
  const [inputBoxStatus, setInputBoxStatus] = useState('success');
  const [isIconDisplay, setIsIconDisplay] = useState(false);
  const [boxVisible, setBoxVisible] = useState([1, 2]);
  const [editFinalQuestion, setEditFinalQuestion] = useState(false);
  const [loader, setLoader] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [showDontShowAgainWarrningModel, setShowDontShowAgainWarrningModel] = useState(Cookies.get('genrate_question_ai_response_warning') === '1' ? false : true);
  // useref for tect are
  const textRef = useRef(null);

  const navigate = useNavigate();

  const redirect = () => {
    if (isLogin()) {
      navigate('/request', {
        state: { question },
      });
    } else {
      navigate('/signup');
      localStorage.setItem('redirectLink', `${window.location.origin}/request`);
    }
  };
  function toGenerateQuestionWithAI() {
    let userRec = {};
    if (isLogin()) {
      if (Object.values(localData).length > 0) {
        userRec = localData;
      } else {
        userRec = localStorage.getItem('user_question')
          ? JSON.parse(localStorage.getItem('user_question'))
          : {};
      }
      if (Object.keys(userRec).length > 0) {
        let formData = {
          location: userRec?.location_id?.name || '',
          invoice: userRec?.invoices_id?.name || '',
          sub_category: userRec?.sub_category_id?.name || '',
          category: userRec?.category_id?.name || '',
          question_type: userRec?.question_type_id?.name || '',
          sector: userRec?.sector_id?.name || '',
          sub_sector: userRec?.sub_sector_id?.name || '',
          customer_type: userRec?.customer_type_id?.name || '',
          sub_category_other_question: userRec?.sub_category_other_question || '',
        };
        dispatch({
          type: 'GENRATE_QUESTION_WITH_AI',
          formData: formData,
        });
      }
    }
  }

  // handle useefeect here
  // handle useefeect here
  useEffect(() => {
    if (step === step_Constants.STEP_1.type) {
      setTypeWriterDisplayText(step_Constants.STEP_1.text);
      setLaterBtnVisible(!step_Constants.STEP_1.isRequired);
    } else if (step === step_Constants.STEP_2.type) {
      setTypeWriterDisplayText(step_Constants.STEP_2.text);
      setLaterBtnVisible(!step_Constants.STEP_2.isRequired);
    } else if (step === step_Constants.STEP_3.type) {
      if (!isBSLoading && businessTypeCategoryList) {
        setCapsulViewRecord(businessTypeCategoryList?.data);
        setTypeWriterDisplayText(step_Constants.STEP_3.text);
      }
      setLaterBtnVisible(!step_Constants.STEP_3.isRequired);
    } else if (step === step_Constants.STEP_3_SUB_CAT.type) {
      if (!isBSLoading && businessTypeCategoryList) {
        setCapsulViewRecord(businessTypeCategoryList?.data);
        setTypeWriterDisplayText(step_Constants.STEP_3_SUB_CAT.text);
      }
      setLaterBtnVisible(!step_Constants.STEP_3_SUB_CAT.isRequired);
    } else if (step === step_Constants.STEP_4.type) {
      if (!isInvoiceLoading && invoiceList) {
        setCapsulViewRecord(invoiceList);
        setTypeWriterDisplayText(step_Constants.STEP_4.text);
        setLaterBtnVisible(!step_Constants.STEP_4.isRequired);
      }
    } else if (step === step_Constants.STEP_4_LANGUAGES.type) {
      if (!isLangLoading && languageList) {
        const updatedLanguages = languageList.filter(language => language.name !== 'English');
        setCapsulViewRecord(updatedLanguages);
        setTypeWriterDisplayText(step_Constants.STEP_4_LANGUAGES.text);
        setLaterBtnVisible(!step_Constants.STEP_4_LANGUAGES.isRequired);
      }
    } else if (step === step_Constants.STEP_5.type) {
      if (!isLocationLoading && locationList) {
        setCapsulViewRecord(() => locationList);
        setTypeWriterDisplayText(() => step_Constants.STEP_5.text);
      }
      setLaterBtnVisible(!step_Constants.STEP_5.isRequired);
    } else if (step === step_Constants.STEP_6.type) {
      if (!isSCLoading && serviceCategoryList) {
        setCapsulViewRecord(() => serviceCategoryList);
        setTypeWriterDisplayText(() => step_Constants.STEP_6.text);
      }
      setLaterBtnVisible(!step_Constants.STEP_6.isRequired);
    } else if (step === step_Constants.STEP_6_SUB_CAT.type) {
      if (subCatGetRecordsMessage === 'success' && subCatRecords && subCatRecords?.data) {
        setCapsulViewRecord(() => subCatRecords.data);
        let index = subCatRecords.data.length > 0 ? subCatRecords?.data[0]?.parent_id - 1 : 0;
        setTypeWriterDisplayText(() => step_Constants.STEP_6_SUB_CAT.text[index]);
      }
      setLaterBtnVisible(!step_Constants.STEP_6_SUB_CAT.isRequired);
    } else if (step === step_Constants.STEP_6_OTHERS.type) {
      let index = subCatRecords?.data?.length > 0 ? subCatRecords?.data[0]?.parent_id - 1 : 0;
      setTypeWriterDisplayText(step_Constants.STEP_6_SUB_CAT.others[index]);
      setSubCatQuestionText(localData?.sub_category_other_question || '');
      setCapsulViewRecord([]);
    } else if (step === step_Constants.STEP_7.type) {
      let text = '';
      if (isLogin()) {
        text = questionGenerated;
        if (text && Cookies.get('genrate_question_ai_response_warning') != '1') {
          setWarningModalVisible(true);
        }
      } else {
        text = `Based on the information you provided, we’ve done our best to diagnose your issue and understand your needs.`;
      }

      setTypeWriterDisplayText(text);
    }
  }, [
    step,
    isInvoiceLoading,
    businessTypeCategoryList,
    locationList,
    serviceCategoryList,
    subCatRecords,
    questionGenerated,
    isLangLoading,
    languageList,
  ]);

  // check is user question exists in localstorage
  useEffect(() => {
    if (isLogin()) {
      let question = localStorage.getItem('user_question');
      if (question) {
        question = JSON.parse(question);
        if (question && question?.category_id) {
          toGenerateQuestionWithAI();
          setLocalData(question);
          setStep(step_Constants.STEP_7.type);
          setLaterBtnVisible(false);
        }
      }
    }
  }, []);

  // use effect for handling question submit
  useEffect(() => {
    if (fullQuestionCreateMsg === 'success' && isLogin()) {
      localStorage.removeItem('user_question');
      setBoxVisible([1, 2]);
      setEditFinalQuestion(false);
      setLocalData({});
      setLoader(false);
      navigate('/my-request/' + genratedQuestionId);
      dispatch({ type: 'CLEAR_STEPS' });
      setStep(step_Constants.STEP_1.type);
    }
  }, [fullQuestionCreateMsg]);

  const handleClickEvents = values => {
    let rec = {};
    if (step === step_Constants.STEP_1.type) {
      if (values == 'box-1') {
        rec = {
          question_type_id: {
            name: 'Consult with a financial professional',
            id: 1,
          },
        };
      } else if (values == 'box-2') {
        rec = {
          question_type_id: {
            name: 'Hire a fractional financial professional',
            id: 2,
          },
        };
        setBoxVisible([3, 4]);
      }
      setStep(step_Constants.STEP_2.type);
    } else if (step === step_Constants.STEP_2.type) {
      if (values === 'box-1') {
        dispatch({ type: 'GET_INVOICE_REQUEST', formData: { status: 'active' } });
        rec = {
          customer_type_id: {
            id: 1,
            name: 'Individual',
          },
          sector_id: null,
          sub_sector_id: null,
        };

        setStep(step_Constants.STEP_4.type);
      } else if (values === 'box-2') {
        dispatch({ type: 'GET_BUSINESS_SECTOR_CATEGORY_REQUEST', id: null });
        rec = {
          customer_type_id: {
            id: 2,
            name: 'Business',
          },
        };
        setStep(step_Constants.STEP_3.type);
      }
      setBoxVisible([]);
    } else if (step === step_Constants.STEP_3.type) {
      rec = {
        sector_id: {
          id: values?.id || null,
          name: values?.name || null,
          parent_id: values?.parent_id || null,
        },
      };
      if (values?.id === null) {
        rec = {
          ...rec,
          sub_sector_id: null,
        };
      }
      dispatch({ type: 'GET_BUSINESS_SECTOR_CATEGORY_REQUEST', id: values?.id });

      if (values?.id !== null) {
        setStep(step_Constants.STEP_3_SUB_CAT.type);
      } else {
        dispatch({ type: 'GET_INVOICE_REQUEST', formData: { status: 'active' } });
        setStep(step_Constants.STEP_4.type);
      }
    } else if (step === step_Constants.STEP_3_SUB_CAT.type) {
      rec = {
        sub_sector_id: {
          id: values?.id || null,
          name: values?.name || null,
          parent_id: values?.parent_id || null,
        },
      };
      dispatch({ type: 'GET_INVOICE_REQUEST', formData: { status: 'active' } });
      setStep(step_Constants.STEP_4.type);
    } else if (step === step_Constants.STEP_4.type) {
      rec = {
        invoices_id: { id: values?.id || null, name: values?.name || null },
      };
      dispatch({ type: 'GET_LANGUAGE_REQUEST', formData: { status: 'active' } });
      setStep(step_Constants.STEP_4_LANGUAGES.type);
      setIsIconDisplay(false);
    } else if (step === step_Constants.STEP_4_LANGUAGES.type) {
      rec = {
        language_id: { id: values?.id || null, name: values?.name || null },
      };
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: {} });
      setStep(step_Constants.STEP_5.type);
      setIsIconDisplay(false);
    } else if (step === step_Constants.STEP_5.type) {
      rec = {
        location_id: { id: values?.id || null, name: values?.name || null },
      };
      dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
      setStep(step_Constants.STEP_6.type);
      setIsIconDisplay(true);
    } else if (step === step_Constants.STEP_6.type) {
      rec = {
        category_id: { id: values?.id || null, name: values?.name || null },
      };
      if (values?.name == 'Payroll Management') {
        toGenerateQuestionWithAI();
        setStep(step_Constants.STEP_7.type);
        rec = {
          ...rec,
          sub_category_id: '',
          sub_category_other_question: '',
        };
      } else {
        dispatch({
          type: 'QUESTION_SUB_CATEGORY_REQUEST',
          formData: { parent_id: values?.id, per_page: 'all' },
        });
        setStep(step_Constants.STEP_6_SUB_CAT.type);
        setIsIconDisplay(false);
      }
    } else if (step === step_Constants.STEP_6_SUB_CAT.type) {
      rec = {
        sub_category_id: {
          id: values?.id || null,
          name: values?.name || null,
          parent_id: values?.parent_id || null,
        },
        sub_category_other_question:
          localData?.sub_category_id?.id == values?.id && localData?.sub_category_other_question
            ? localData?.sub_category_other_question
            : '',
      };

      if (values?.name == 'Other' || values?.name == 'Others') {
        setStep(step_Constants.STEP_6_OTHERS.type);
      } else {
        toGenerateQuestionWithAI();
        setStep(step_Constants.STEP_7.type);
      }
    } else if (step === step_Constants.STEP_6_OTHERS.type) {
      if (subCatQuestionText.trim() !== '') {
        rec = {
          sub_category_other_question: subCatQuestionText || '',
        };
        toGenerateQuestionWithAI();
        setStep(step_Constants.STEP_7.type);
        setSubCatQuestionText('');
      } else {
        setInputBoxStatus('error');
      }
    }

    handleUserQuestionlocalStorageRecords(rec);
    setLaterBtnVisible(false);
  };

  // function to handle back navigation in question area
  function handleBackNavgiation() {
    if (step === step_Constants.STEP_2.type) {
      setStep(step_Constants.STEP_1.type);
      setBoxVisible([1, 2]);
    } else if (step === step_Constants.STEP_3.type) {
      setBoxVisible([3, 4]);
      setStep(step_Constants.STEP_2.type);
    } else if (step === step_Constants.STEP_3_SUB_CAT.type) {
      dispatch({ type: 'GET_BUSINESS_SECTOR_CATEGORY_REQUEST', id: null });
      setStep(step_Constants.STEP_3.type);
    } else if (step === step_Constants.STEP_4.type) {
      if (localData?.sector_id?.id) {
        businessTypeCategoryList &&
          businessTypeCategoryList?.length == 0 &&
          dispatch({ type: 'GET_BUSINESS_SECTOR_CATEGORY_REQUEST', id: null });
        setStep(step_Constants.STEP_3.type);
      } else {
        setStep(step_Constants.STEP_2.type);
      }
      setBoxVisible([3, 4]);
    } else if (step === step_Constants.STEP_4_LANGUAGES.type) {
      setStep(step_Constants.STEP_4.type);
      setBoxVisible([3, 4]);
    } else if (step === step_Constants.STEP_5.type) {
      invoiceList &&
        invoiceList?.length == 0 &&
        dispatch({ type: 'GET_INVOICE_REQUEST', formData: { status: 'active' } });
      setStep(step_Constants.STEP_4_LANGUAGES.type);
    } else if (step === step_Constants.STEP_6.type) {
      locationList &&
        locationList?.length == 0 &&
        dispatch({ type: 'GET_LOCATION_REQUEST', formData: {} });
      setStep(step_Constants.STEP_5.type);
      setIsIconDisplay(false);
    } else if (step === step_Constants.STEP_6_SUB_CAT.type) {
      serviceCategoryList &&
        serviceCategoryList?.length == 0 &&
        dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
      dispatch({ type: 'Empty_SUB_CATEGORY' });
      setStep(step_Constants.STEP_6.type);
      setIsIconDisplay(true);
    } else if (step === step_Constants.STEP_6_OTHERS.type) {
      subCatRecords &&
        subCatRecords?.length == 0 &&
        dispatch({
          type: 'QUESTION_SUB_CATEGORY_REQUEST',
          formData: { parent_id: localData?.sub_category_id?.parent_id || 0, per_page: 'all' },
        });
      setStep(step_Constants.STEP_6_SUB_CAT.type);
      setSubCatQuestionText('');
    } else if (step === step_Constants.STEP_7.type) {
      if (localData && localData?.sub_category_other_question !== '') {
        setSubCatQuestionText(localData?.sub_category_other_question);
        setStep(step_Constants.STEP_6_OTHERS.type);
      } else if (localData && localData?.category_id?.name == 'Payroll Management') {
        serviceCategoryList &&
          serviceCategoryList?.length == 0 &&
          dispatch({ type: 'GET_SERVICE_CATEGORY_REQUEST', formData: { status: 'active' } });
        dispatch({ type: 'Empty_SUB_CATEGORY' });
        setStep(step_Constants.STEP_6.type);
      } else {
        subCatRecords &&
          subCatRecords?.length == 0 &&
          dispatch({
            type: 'QUESTION_SUB_CATEGORY_REQUEST',
            formData: { parent_id: localData?.sub_category_id?.parent_id || 0, per_page: 'all' },
          });
        setStep(step_Constants.STEP_6_SUB_CAT.type);
        setSubCatQuestionText('');
      }
      dispatch({ type: 'GENRATE_QUESTION_WITH_AI_ERROR' });
    }
    setCapsulViewRecord([]);
    setLaterBtnVisible(false);
    setSignupTextVisible(false);
    setInputBoxStatus('success');
    setEditFinalQuestion(false);
  }

  // handle users question local storage record
  const handleUserQuestionlocalStorageRecords = values => {
    if (localStorage.getItem('user_question')) {
      let userRecords = JSON.parse(localStorage.getItem('user_question'));
      let newRec = { ...userRecords, ...values };
      localStorage.setItem('user_question', JSON.stringify(newRec));
      setLocalData(newRec);
    } else {
      localStorage.setItem('user_question', JSON.stringify(values));
      setLocalData(values);
    }
  };

  function handleTypeWriterCallEvent() {
    if (!isLogin()) {
      setSignupTextVisible(true);
    }
  }

  // spring web -------------------//
  const transApi = useSpringRef();

  const transition = useTransition(capsulViewRecord || [], {
    ref: transApi,
    trail: 1000 / 6,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });
  //
  const colApi = useSpringRef();

  const Coltransition = useTransition(boxVisible, {
    ref: colApi,
    trail: 250,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain([transApi, colApi], [0, 0.1]);

  // function to hanlde click event on div
  function handleTypeWritterClickEvent() {
    if (isLogin() && typeWriterDisplayText.length > 0) {
      setEditFinalQuestion(true);
    }
  }

  function onFinsishQuestionClick() {
    if (isLogin() && typeWriterDisplayText.length > 0) {
      const allRec = JSON.parse(localStorage.getItem('user_question'));
      if (allRec) {
        let formData = {
          user_id: getUserData().id || null,
          question_type_id: allRec?.question_type_id?.id || null,
          expert_approved: 0,
          assign_by_admin: 1,
          request_submitted: 1,
          question_disc: typeWriterDisplayText || null,
          customer_type_id: allRec?.customer_type_id?.id || null,
          category_id: allRec?.category_id?.id || null,
          sub_category_id: allRec?.sub_category_id?.id || null,
          sector_id: allRec?.sub_sector_id?.id || null,
          invoices_id: allRec?.invoices_id?.id || null,
          matching_automation: 1,
          location_id: allRec?.location_id?.id || null,
          language_id: allRec?.language_id?.id || null,
          other_options:
            (allRec?.sub_category_other_question !== '' &&
              JSON.stringify([
                { sub_category_other_question: allRec?.sub_category_other_question },
              ])) ||
            null,
          other_sub_category_name:
            allRec?.sub_category_other_question !== '' && allRec?.sub_category_other_question,
        };
        setLoader(true);

        dispatch({
          type: 'FULL_QUESTION_CREATE_REQUEST',
          formData: {
            formData,
          },
          requestType: 'post',
        });
      }
    }
  }

  const handleGotItClick = () => {
    Cookies.set('genrate_question_ai_response_warning', showDontShowAgainWarrningModel ? '1' : null, { expires: 100 });
    setWarningModalVisible(false);
  }
  return (
    <div className="home-page">
      <WarningModal
        showWarningModal={warningModalVisible}
        text="Click on the edit icon or the text to start customizing and then press Continue when done. Use this editable template to specify your exact needs, ensuring our experts can serve you precisely. We&#39;ve crafted this template to kickstart your thoughts, inspire your input, and save you the effort of starting from scratch."
        image={editPencilImg}
        handleWarningModelGotIt={handleGotItClick}
        showDontShowAgain={true}
        setDontShowAgain={setShowDontShowAgainWarrningModel}
      />
      <Helmet title="Home" />
      <div className="cover-img">
        <div className="text-on-img" >
          <div className="section" style={{ zIndex: 1111 }}>
            {step !== step_Constants.STEP_7.type && (
              <div style={{ marginLeft: '10px' }}>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: false,
                    cursor: '|',
                    strings: typeWriterDisplayText,
                    delay: 10,
                  }}
                />
              </div>
            )}
            <Row
              className="mt-10"
              style={{
                maxHeight: '400px',
                overflow: 'auto',
                minHeight: '200px',
              }}
            >
              {step === step_Constants.STEP_1.type || step === step_Constants.STEP_2.type ? (
                <>
                  {Coltransition((style, item) => (
                    <>
                      {item === 1 || item === 3 ? (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '10px' }}>
                          <animated.span
                            style={{
                              willChange: 'transform, opacity',
                              ...style,
                            }}
                          >
                            <div className="question_main_container">
                              <Tooltip placement="top" title={step === step_Constants.STEP_1.type ? "Choose this option for a free one-time consultation to ask an accounting, bookkeeping, taxation, or business financial management expert their opinion about your case. Perfect for immediate guidance on specific financial decisions." : ""}
                                zIndex={5}
                                overlayInnerStyle={{ fontSize: '1rem', lineHeight: '1.7rem', padding: '20px', fontWeight: '400', width: '160%', backgroundColor: 'rgb(24, 119, 242,0.8)', backdropFilter: 'blur(5px)' }}
                                overlayClassName='tool-tip'
                              >

                                <div
                                  className="box-1"
                                  style={{
                                    width: step === step_Constants.STEP_2.type ? '300px' : '475px',
                                  }}
                                  onClick={() => handleClickEvents('box-1')}
                                >
                                  <img
                                    className="question_icon"
                                    src={
                                      step === step_Constants.STEP_1.type
                                        ? SearchLogo
                                        : IndividualLogo
                                    }
                                    alt=""
                                  />
                                  <p className="box-text">
                                    {step === step_Constants.STEP_1.type
                                      ? 'Consult with a financial professional'
                                      : 'Individual'}
                                  </p>
                                </div>
                              </Tooltip>
                            </div>
                          </animated.span>
                        </Col>
                      ) : (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '10px' }}>
                          <animated.span
                            style={{
                              willChange: 'transform, opacity',
                              ...style,
                            }}
                          >
                            <Tooltip placement="top" title={step === step_Constants.STEP_1.type ? "Choose this option if you need one-time or ongoing accounting, bookkeeping, taxation, or business financial management expertise without committing to a full-time hire. Gain continuous access to professional services tailored toyour business needs." : ""}
                              // color="#1877f2"
                              zIndex={5}
                              overlayInnerStyle={{ fontSize: '1rem', lineHeight: '1.7rem', padding: '20px', fontWeight: '400', width: '160%', backgroundColor: 'rgb(24, 119, 242,0.8)', backdropFilter: 'blur(5px)' }}
                              overlayClassName='tool-tip'
                            >
                              <div
                                className="box-2"
                                style={{
                                  width: step === step_Constants.STEP_2.type ? '300px' : '475px',
                                }}
                                onClick={() => handleClickEvents('box-2')}
                              >
                                <img
                                  className="question_icon"
                                  src={
                                    step === step_Constants.STEP_1.type ? calculaterLogo : Business
                                  }
                                  alt=""
                                />
                                <p className="box-text">
                                  {step === step_Constants.STEP_1.type
                                    ? 'Hire a fractional financial professional'
                                    : 'Business'}
                                </p>
                              </div>
                            </Tooltip>
                          </animated.span>
                        </Col>
                      )}
                    </>
                  ))}
                </>
              ) : step === step_Constants.STEP_7.type ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ minHeight: '25rem', }}>
                  {isLogin() && (
                    <p style={{ fontSize: '20px', color: 'black', lineHeight: '30px' }}>
                      {' '}
                      Based on the information you provided, here's how we understand your request.
                      In the text shown below, you can customize it to match your specific case by
                      typing, adding, deleting, or adjusting as necessary. You can also go back and
                      provide any skipped information so we can serve you better. <InfoCircleOutlined
                        style={{ fontSize: '25px', color: '#1877f2' }} onClick={() => setWarningModalVisible(true)} />
                    </p>
                  )}
                  {editFinalQuestion ? (
                    <>
                      <TextArea
                        value={typeWriterDisplayText}
                        rows={5}
                        ref={textRef}
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                          fontSize: '22px',
                          resize: 'none',
                        }}
                        onChange={e => setTypeWriterDisplayText(e.target.value)}
                        autoFocus={true}
                      />
                    </>
                  ) : (
                    <>
                      {typeWriterDisplayText.length > 0 ? (
                        <div
                          id="geminiTypewriter"
                          className={isLogin() && 'geminiTypewriterClass'}
                          onClick={() => handleTypeWritterClickEvent()}
                          style={{ position: 'relative', height: '100%', }}
                        >
                          <div className={!isLogin() && 'text-to-blur'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <Typewriter
                              onInit={typewriter => {
                                typewriter.callFunction(() => {
                                  handleTypeWriterCallEvent();
                                });
                              }}
                              options={{
                                autoStart: true,
                                loop: false,
                                cursor: '|',
                                strings: typeWriterDisplayText,
                                delay: 25,
                              }}
                            />
                            {signupTextVisible && (
                              <span className="Typewriter__wrapper">
                                Please{' '}
                                <Link
                                  to={'/login'}
                                  onClick={() => localStorage.setItem('redirectLink', `/`)}
                                >
                                  Signup
                                </Link>{' '}
                                to see our recommendations
                              </span>
                            )}
                          </div>
                          {!isLogin() && (
                            <Typewriter
                              onInit={typewriter => {
                                typewriter.callFunction(() => {
                                  handleTypeWriterCallEvent();
                                });
                              }}
                              options={{
                                autoStart: true,
                                loop: false,
                                cursor: '|',
                                strings:
                                  'This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation. Please register to see detailed report.This is sample report text animation.',
                                delay: 0,
                                wrapperClassName: 'geminiTypewriterClassForBlueText',
                                cursorClassName: 'geminiTypewriterCursorClassForBlurText',
                              }}
                            />
                          )}
                          {isLogin() && (
                            <Tooltip title="Edit" color='#1c64f2'>
                              <EditOutlined
                                onClick={() => handleTypeWritterClickEvent()}
                                id="editIcon"
                              />
                            </Tooltip >

                          )}
                        </div>
                      ) : (
                        <span id="loadingSpan">
                          <LoadingOutlined style={{ fontSize: '2rem' }} /> Just a moment, drafting
                          your request…….
                        </span>
                      )}
                    </>
                  )}
                </Col>
              ) : step === step_Constants.STEP_6_OTHERS.type ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '20px' }}>
                  <Input
                    value={subCatQuestionText}
                    onChange={e => setSubCatQuestionText(e.target.value)}
                    maxLength={50}
                    placeholder="Enter Your Option"
                    style={{
                      width: '50%',
                      borderWidth: 1,
                      height: '40px',
                      backgroundColor: '#f2f2f2',
                    }}
                    autoFocus={true}
                    status={inputBoxStatus}
                    onKeyDown={e => {
                      (e.key === 'Enter' || e.key === 'NumpadEnter') &&
                        handleClickEvents({ id: null, name: null });
                    }}
                  />
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {transition((style, item) => (
                    <animated.span
                      style={{
                        willChange: 'transform, opacity',
                        ...style,
                      }}
                    >
                      <Capsule
                        item={item}
                        handleCapsuleClickEvent={handleClickEvents}
                        localData={localData}
                        isIconDisplay={isIconDisplay}
                        icons={step_Constants?.icons}
                      />
                    </animated.span>
                  ))}
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: '10px' }}>
                <div className="footer_buttons">
                  {step !== step_Constants.STEP_1.type && (
                    <Button
                      style={{
                        borderColor: '#1c64f2',
                        color: '#1c64f2',
                        backgroundColor: 'transparent',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                      }}
                      htmlType="button"
                      className=""
                      size="large"
                      onClick={() => handleBackNavgiation()}
                      disabled={loader}
                    >
                      <ArrowLeftOutlined /> Back
                    </Button>
                  )}
                  {laterBtnVisible && (
                    <Button
                      style={{
                        borderColor: '#1c64f2',
                        color: '#1c64f2',
                        backgroundColor: 'transparent',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                      }}
                      htmlType="button"
                      className=""
                      onClick={() => handleClickEvents({ id: null, name: null })}
                      size="large"
                    >
                      Later
                    </Button>
                  )}
                  {step === step_Constants.STEP_6_OTHERS.type && (
                    <Button
                      style={{
                        borderColor: '#1c64f2',
                        color: '#1c64f2',
                        backgroundColor: 'transparent',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                      }}
                      htmlType="button"
                      className=""
                      size="large"
                      onClick={() => handleClickEvents({ id: null, name: null })}
                    >
                      Next
                    </Button>
                  )}
                  {step === step_Constants.STEP_7.type && isLogin() && (
                    <Button
                      style={{
                        borderColor: '#1c64f2',
                        color: '#1c64f2',
                        backgroundColor: 'transparent',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                      }}
                      htmlType="button"
                      className=""
                      size="large"
                      onClick={() => onFinsishQuestionClick()}
                      disabled={typeWriterDisplayText.trim().length === 0}
                      loading={loader}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <section className="section2">
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col xs={24} sm={12} md={12} lg={12} xl={12} className="offering-left">
            <p className="h-title">We offer solutions for</p>
            <div className="box">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Startups & SMBs</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      For those startup businesses, or for small- and medium-sized businesses
                      (SMBs), who seek access to on-demand, high-quality bookkeeping, accounting,
                      tax, and business advisory services which also fall within budget.
                    </li>
                  </ul>
                </p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Those solutions could be tracking income and expenditures, ensuring statutory
                      compliance, or representing relevant and understandable financial information
                      to investors and bankers.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Individuals</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Individuals who would like to smartly manage the financial aspects of their
                      lives.
                    </li>
                    <br />
                    <li>
                      Those who would like to avoid paying more than they should during tax season.
                    </li>
                    <br />
                    <li>And/or, those who wish to avoid tax penalties.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Investors and non-financial decision-makers</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Investors who want advice from a professional before committing to investing
                      in a particular business.
                    </li>
                    <br />
                    <li>
                      Decision-makers who would like to have an expert opinion before they make a
                      strategic financial decision that may impact their business's financial
                      position or profitability.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="box mt25">
              <img src={TextIcon} alt="icon" />
              <div className="benefits-sub-text">
                <p className="title">Financial executives and other financial professionals</p>
                <p className="sub-title">
                  <ul>
                    <li>
                      Executive and financial professionals who are dealing with a new subject
                      matter. Those executives and professionals may not have enough training or
                      knowledge to handle the new matter, leading to the need for help of other
                      specialized accounting and business advisors.
                    </li>
                  </ul>
                </p>
                <p className="sub-title">
                  <ul>
                    <ul>
                      <li>
                        If you feel you do not fit within these categories, we can still help you.
                        Contact Accountizer to learn more about our complete accounting solutions.
                      </li>
                    </ul>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setVisible(true);
                }, [100]);
              }}
            >
              <img
                style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                src={client_home_thumbnail}
                alt="Accountizer Homepage"
              />
            </div>
          </Col>
          <Modal
            open={visible}
            footer={null}
            onCancel={() => {
              setIsPaused(true);
              setTimeout(() => {
                setVisible(false);
              }, [100]);
            }}
            bodyStyle={{ padding: 0 }}
            centered={true}
            width={(width * 68) / 100}
            style={{ height: (height * 75.5) / 100 }}
          >
            <ReactPlayer
              className="react-vimeo-player-modal"
              url="https://vimeo.com/809516724"
              controls={true}
              stopOnUnmount={true}
              playing={!ispaused}
              width={(width * 65) / 100}
              height={(height * 75.5) / 100}
            />
          </Modal>
        </Row>
      </section>
      <section className="section3">
        <div className="benefit">
          <div className="tc">
            <p className="h-title">Snapshots of common client requests</p>
            <div
              style={{ marginBottom: '25px', cursor: 'pointer' }}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setModal2Visible(true);
                }, [100]);
              }}
            >
              <img style={{ marginBottom: '10px' }} src={videoCameraIcon} alt="" />
              <p className="sub-title">Watch Video</p>
            </div>

            <Modal
              open={modal2Visible}
              footer={null}
              onCancel={() => {
                setIsPaused(true);
                setTimeout(() => {
                  setModal2Visible(false);
                }, [100]);
              }}
              bodyStyle={{ padding: 0 }}
              centered={true}
              width={(width * 68) / 100}
              style={{ height: (height * 75.5) / 100 }}
            >
              <ReactPlayer
                className="react-vimeo-player-modal"
                url="https://vimeo.com/813375213"
                controls={true}
                stopOnUnmount={true}
                playing={!ispaused}
                width={(width * 65) / 100}
                height={(height * 75.5) / 100}
              />
            </Modal>
            <br />
            <p className="sub-title">
              These are areas in which our clients request the most help. But no matter your need,
              we can find the right solution for you.
            </p>
            <div className="mt50 box">
              <Row gutter={[35, 35]}>
                {benefitList.map((item, idx) => {
                  return (
                    <Col key={idx} xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Card key={item.id}>
                        <img width={30} src={require(`../../assets/images/categoryImages/${item.icon}`)} alt="Accointizer Benifits"></img>
                        <p className="title mt20">{item.title}</p>
                        <p className="desc">{item.desc}</p>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </section>
      <section className="section4">
        <div className="tc">
          <p className="h-title">How it works</p>
          <div
            style={{ marginBottom: '25px' }}
            onClick={() => {
              setIsPaused(false);
              setTimeout(() => {
                setModal3Visible(true);
              }, [100]);
            }}
          >
            <img style={{ marginBottom: '10px', cursor: 'pointer' }} src={videoCameraIcon} alt="" />
            <p className="sub-title">Watch Video</p>
          </div>
          <Modal
            open={modal3Visible}
            footer={null}
            onCancel={() => {
              setIsPaused(true);
              setTimeout(() => {
                setModal3Visible(false);
              }, [100]);
            }}
            bodyStyle={{ padding: 0 }}
            centered={true}
            width={(width * 68) / 100}
            style={{ height: (height * 75.5) / 100 }}
          >
            <ReactPlayer
              className="react-vimeo-player-modal"
              url="https://vimeo.com/812932838"
              controls={true}
              stopOnUnmount={true}
              playing={!ispaused}
              width={(width * 65) / 100}
              height={(height * 75.5) / 100}
            />
          </Modal>
          <p className="sub-title heading">Crunching your numbers can be easier than you think.</p>
        </div>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork} alt="Accountizer Homepage" />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Describe your needs</p>
              <p className="sub-title">
                Explain your case, and answer our few simple questions that were designed to help us
                better understand your needs. Even if you are unsure about the help you need, no
                worries; you are in the right place, and we can still help.
              </p>
            </div>
          </Col>
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          {width <= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Get matched with an expert</p>
              <p className="sub-title">
                We will connect you with a carefully picked professional who is pre-vetted for
                qualification and training, and for experience in handling clients with similar
                requirement.
              </p>
              <p className="sub-title">
                Alternatively, pick your preferred professional from our pre-vetted experts' pool
                and start communicating with your chosen professional right away.
              </p>
            </div>
          </Col>
          {width >= 576 && (
            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img style={{ width: '100%' }} src={howItWork2} alt="Accountizer Homepage" />
              </div>
            </Col>
          )}
        </Row>
        <Row
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className="mt50 benefits-row-container"
          gutter={[75]}
        >
          <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img style={{ width: '100%' }} src={howItWork3} alt="Accountizer Homepage" />
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex-center benefits-description-wrapper"
          >
            <div className="benefits-sub-text">
              <p className="m-title">Pay only when you are fully satisfied</p>
              <p className="sub-title">
                After you accept the expert’s quote for the agreed-upon service(s), you will be
                charged for the service. However, the service fee will not be released to the
                experts until they successfully complete your job.
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section className="section6">
        <Card>
          <Row gutter={75}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex-center">
              <div>
                <p className="title mb20">Ready to get started?</p>
                <p className="sub-title mb25">
                  Choose one of our two search options to access endless resources of handpicked
                  professionals and get your work done reliably, efficiently, and at your
                  convenience.
                </p>
                <Button className="" size="large" onClick={() => navigate('/find-expert')}>
                  Get Started &nbsp;
                  <ArrowRightOutlined />
                  &nbsp;
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="tr">
              <img style={{ width: '100%' }} src={howItWork4} alt="Accountizer Homepage"></img>
            </Col>
          </Row>
        </Card>
      </section>

    </div>
  );
};

export default HomePage;

const benefitList = [
  {
    id: 1,
    title: 'Tax',
    desc: 'Preparation and filing for online businesses',
    icon: "tax.png"
  },
  {
    id: 2,
    title: 'Bookkeeping',
    desc: 'Accounting software implementation and training: QuickBooks, FreshBooks, Sage, Zoho, Xero, or any other accounting and bookkeeping software',
    icon: "bookkeeping.png"
  },
  {
    id: 3,
    title: 'Accountant',
    desc: 'For work in bookkeeping, year-end reviews, or year-end and tax preparation',
    icon: "accounting.png"
  },
  {
    id: 4,
    title: 'Cost Accountant',
    desc: 'For setting up inventory cycles and for managing COGS',
    icon: "accounting.png"
  },
  {
    id: 5,
    title: 'Business advisor',
    desc: 'Financial information analysis to pinpoint workflow weaknesses that lead to losses',
    icon: "business.png"
  },
  {
    id: 6,
    title: 'Payroll management',
    desc: 'For employees and contractors, bi-weekly payroll, direct deposit',
    icon: "payroll.png"
  },
];
