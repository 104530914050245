import React, { useState } from 'react';
import { Layout, Row, Col, Modal } from 'antd';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import { AiOutlineTwitter, AiFillLinkedin, AiFillYoutube } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { SOCIAL } from '../../helpers/constant';
import { useLocation } from 'react-router-dom';
import googleMap from '../../assets/images/google-maps.svg';
import { TikTokFilled } from '@ant-design/icons';
import { IframPdfModal } from '../common/modal/iframPdfModal';
import Privacy_policy from '../../assets/documents/Privacy_policy.pdf';
import Service_provider_agreement from '../../assets/documents/Service_provider_agreement.pdf';




const { Footer } = Layout;

const PublicFooter = props => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const [modalVisible, setModalVisible] = useState(false);
  const [ispaused, setIsPaused] = useState(false);
  const [showServiceProviderModal, setShowServiceProviderModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const location = useLocation();
  const navigation = useNavigate();
  const handleExpertNavigationClick = () => {
    if (location.pathname === '/expert') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    navigation('/expert');
  }
  return (
    <Footer className="public-footer">
      <Row gutter={35}>
        <Col xs={9} sm={9} md={9} lg={9} xl={9} className="">
          <img src={MainLogo} style={{ width: '50%' }} alt="Accountizer Logo "></img>
          <p className="mt20 footer-text">© 2024 Accountizer, Inc. All rights reserved.</p>
          <div>
            <span className="mr10">
              <AiOutlineTwitter
                onClick={() => window.open(SOCIAL?.twitter?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span className="mr10">
              <RiFacebookFill
                onClick={() => window.open(SOCIAL?.facebook?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span className="mr10">
              <AiFillLinkedin
                onClick={() => window.open(SOCIAL?.linkedIn?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span className="mr10">
              <AiFillYoutube
                onClick={() => window.open(SOCIAL?.youtube?.page, '_blank')}
                className="cursor-p"
                size={22}
                color="#6B7280"
              />
            </span>
            <span >
              <TikTokFilled
                onClick={() => window.open(SOCIAL?.tiktok?.page, '_blank')}
                className="cursor-p"
                style={{
                  color: "#6B7280",
                  fontSize: "23px",
                }}
              />
            </span>
          </div>
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="">
          <p className="footer-label">ACCOUNTIZER</p>
          <p>
            <Link to={'/find-expert'}>Find a professional</Link>
          </p>
          <p>
            <Link to={'/about-us'}>About Accountizer</Link>
          </p>
          <p>
            <Link to={'/blogs'}>Blogs</Link>
          </p>
          <p>
            <Link to={`${location.pathname === '/expert' ? '/expert/faq' : '/faq'}`}>FAQs</Link>
          </p>
          <p >
            <Link to={'/contact'}>Contact</Link>
          </p>
          <p style={{
            cursor: 'pointer',
            color: '#6B7280',
          }}
            onClick={() => setShowPrivacyModal(!showPrivacyModal)}>
            Privacy Policy
          </p>
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footer-contact">
          <p className="footer-label">For Experts</p>
          <p onClick={handleExpertNavigationClick} style={{ cursor: 'pointer' }}>
            Become an Expert
          </p>
          {location.pathname === '/expert' && (
            <p
              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: '400', color: '#6B7280' }}
              onClick={() => {
                setIsPaused(false);
                setTimeout(() => {
                  setModalVisible(true);
                }, [100]);
              }}
            >
              Why to join us ?
            </p>
          )}
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footer-contact">
          <p className="footer-label">Contact us</p>
          {/* <p></p> */}
          <p>admin@accountizer.ca</p>
          <p>60 King St W, Stoney Creek, ON L8G 1H8, Canada</p>
          <p>
            <Link to={"https://maps.app.goo.gl/nEwJmhnxRtJmcVNP7"} target="_blank">
              <img src={googleMap} alt="Google Map" />
            </Link>
          </p>
        </Col>
        <Modal
          open={modalVisible}
          width={(width * 68) / 100}
          style={{ height: (height * 75.5) / 100 }}
          footer={null}
          onCancel={() => {
            setIsPaused(true);
            setTimeout(() => {
              setModalVisible(false);
            }, [100]);
          }}
          bodyStyle={{ padding: 0 }}
          centered={true}
        >
          <ReactPlayer
            className="react-vimeo-player-modal"
            url="https://vimeo.com/808442055"
            controls={true}
            stopOnUnmount={true}
            playing={!ispaused}
            width={(width * 65) / 100}
            height={(height * 75.5) / 100}
          />
        </Modal>
        <IframPdfModal
          visible={showPrivacyModal}
          setShow={setShowPrivacyModal}
          title="Accountizer's Privacy Policy"
          file={Privacy_policy}
        />
        <IframPdfModal
          visible={showServiceProviderModal}
          setShow={setShowServiceProviderModal}
          title="Accountizer's Service Provider Agreement"
          file={Service_provider_agreement}
        />
      </Row>
    </Footer>
  );
};

export default PublicFooter;
