import React, { useEffect, useState } from 'react';
import Helmet from '../../common/helmet';
import { Row, Card, Col, Input, Tabs, Table, Space, Popover, message, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  formatDateTo,
  getClientRejectionObject,
  getExpertRejectionObject,
  returnQuestionStatusBasedOnId,
} from '../../../helpers/functions';
import DeleteModal from '../../common/modal/deleteModal';
import { AssignQuestionToExpertModal } from '../../common/modal/assignToModal';
import QuestionDetailModal from '../../common/modal/questionDetailModal';
import wrongIcon from '../../../assets/images/red-x-icon.svg';
import checkIcon from '../../../assets/images/CheckCircleIcon.svg';
import exclamationIcon from '../../../assets/images/exclamation-round-icon.svg';

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: '' };

const tabItems = [
  {
    key: '1',
    name: 'new',
    label: `New`,
  },
  {
    key: '2',
    name: 'assigned',
    label: `Assigned`,
  },
  {
    key: '3',
    name: 'archived',
    label: `Archived`,
  },
];

const AdminRequests = () => {
  const dispatch = useDispatch();
  const { expertsList } = useSelector(state => state.expert);
  const { requestsList, isDeleteLoading, isRequestLoading } = useSelector(state => state.admin);
  const { getUserCountFromApi, getQuestionCountFromApi } = useSelector(state => state.requests);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [search, setSearch] = useState({ find: '' });
  const [selectedTab, setSelectedTab] = useState('new');
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [showExpertField, setShowExpertField] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState();
  const [showRequestDetailModal, setShowRequestDetailModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('New Request Detail');

  useEffect(() => {
    getAllRequests({ type: 'request', per_page: 100, status: selectedTab });
  }, [isDeleteLoading]);

  const getAllRequests = (params = {}) => {
    dispatch({ type: 'GET_USER_COUNT' });
    dispatch({
      type: 'GET_QUESTION_COUNT',
      formData: {
        type: 'request',
        status: 'new',
      },
    });
    dispatch({ type: 'ADMIN_REQUESTS_REQUEST', formData: params });
    dispatch({ type: 'EXPERT_LIST_REQUEST' });
    if (expertsList?.length) {
      setRawData(expertsList);
    }
  };

  const doRefresh = () => {
    getAllRequests({ type: 'request', per_page: 100, status: selectedTab });
  };


  useEffect(() => {
    if (!isRequestLoading && requestsList?.data?.length > 0) {
      let tmpArray = [];
      requestsList?.data?.map(item => {
        let tmpObj = { ...item };
        tmpObj.clientName = `${item?.user?.first_name} ${item?.user?.last_name}`;
        tmpObj.clientEmail = (item?.user?.email).toLowerCase();
        tmpObj.categoryName = item?.category ? item?.category?.name : '';
        tmpObj.seniorityType = item?.seniority ? item?.seniority?.name : '';
        tmpObj.date = formatDateTo(item?.created_at,'MMM DD, YYYY hh:mm A');
        tmpObj.status = item?.status?.question_status?.id;
        tmpObj.expertName = item?.expert
          ? `${item?.expert?.first_name} ${item?.expert?.last_name}`
          : '';
        tmpObj.rejectionStatus = item?.question_rejection;
        return tmpArray.push(tmpObj);
      });
      setTableData(tmpArray);
    } else if (!isRequestLoading && requestsList?.data?.length === 0) {
      setTableData([]);
    }
  }, [isDeleteLoading, isRequestLoading]);

  useEffect(() => {
    const filteredData = tableData?.filter(el =>
      `${el.user?.first_name} ${el.user?.last_name}`
        .toLowerCase()
        .includes(search?.find?.toLowerCase()),
    );
    setFilteredTableData(filteredData.filter(data => data?.assign_by_admin === 1));
    if (!search.find.length) {
      setFilteredTableData(tableData.filter(data => data?.assign_by_admin === 1));
    }
  }, [search, tableData, filteredTableData]);

  const handleSearch = e => {
    setSearch({ ...search, find: e.target.value });
  };

  const onTabChange = e => {
    if (e === '1') {
      getAllRequests({ type: 'request', per_page: 100, status: 'new' }); // Submitted question
      setSelectedTab('new');
      setModalTitle('New Request Detail');
      setShowExpertField(false);
    } else if (e === '2') {
      getAllRequests({ type: 'request', per_page: 100, status: 'active' }); // Assigned Question
      setSelectedTab('active');
      setModalTitle('New Request Detail');
      setShowExpertField(true);
    } else if (e === '3') {
      getAllRequests({ type: 'request', per_page: 100, status: 'archive' }); // Archived/Cancel question
      setSelectedTab('archive');
      setModalTitle('New Request Detail');
      setShowExpertField(false);
    }
  };

  const content = data => {
    return (
      <div>
        {selectedTab === 'new' && (
          <div
            style={{
              color: data?.request_submitted === 0 ? 'grey' : 'black',
              cursor: data?.request_submitted === 0 && 'not-allowed',
            }}
            className="action-popover"
            onClick={() => {
              if (data?.request_submitted === 0) {
                message.error('Customer request is incomplete');
              } else {
                setCurrentRecord(data);
                setTimeout(() => {
                  setShowAssignModal(true);
                }, [100]);
              }
            }}
          >
            Assign To
          </div>
        )}
        <div
          className="action-popover"
          onClick={() => {
            setCurrentRecord(data);
            setTimeout(() => {
              setShowDeleteModal(true);
            }, [100]);
          }}
        >
          Delete request
        </div>
      </div>
    );
  };

  return (
    <div className="admin-container">
      <Helmet title="Requests" />
      <p className="title">Requests</p>
      <Row gutter={30} className="page-card-header">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">All Users</p>
                <p className="card-value">
                  {getUserCountFromApi?.allUserCount
                    ? getUserCountFromApi?.allUserCount['all-user']
                    : 0}
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">New Users</p>
                <p className="card-value">
                  {getUserCountFromApi?.allUserCount?.customer
                    ? getUserCountFromApi?.allUserCount?.customer
                    : 0}
                </p>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card className="top-card">
            <div className="parent">
              <div className="mb0">
                <p className="mb-2 card-title">New Requests</p>
                <p className="card-value">
                  {getQuestionCountFromApi ? getQuestionCountFromApi : 0}
                </p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <div>
        <Row className="nav-sub-header mt-10">
          <Col xs={24} sm={24} md={18} lg={14} xl={12}>
            <Search
              defaultValue={filterParams['search']}
              size="large"
              allowClear
              placeholder="Search by client name."
              style={{ width: '100%' }}
              enterButton
              onChange={handleSearch}
            />
          </Col>
        </Row>
      </div>
      <div className="mt15">
        <Tabs defaultActiveKey="1" items={tabItems} onChange={onTabChange} />
        <Table
          pagination={{
            pageSize: 25,
            showSizeChanger: false,
          }}
          size="middle"
          loading={isRequestLoading}
          dataSource={filteredTableData}
        >
          <Column
            title="ID"
            dataIndex="id"
            key="id"
            sorter={(a, b) => a.id - b.id}
            defaultSortOrder="descend"
            showSorterTooltip={false}
          />
          <Column title="Client Name" dataIndex="clientName" key="clientName" />
          <Column
            title="Client Email"
            dataIndex="clientEmail"
            key="clientEmail"
            render={data => {
              return (
                <div>
                  {data.length > 20 ? (
                    <Tooltip title={data}>{data.slice(0, 20) + '...'}</Tooltip>
                  ) : (
                    data
                  )}
                </div>
              );
            }}
          />
          <Column
            title="Request need"
            dataIndex="categoryName"
            key="categoryName"
            render={data => <div>{data ? data : '-'}</div>}
          />

          <Column title="Date" dataIndex="date" key="date" />

          {showExpertField && (
            <Column title="Expert Name" dataIndex="expertName" key="expertName" />
          )}

          {selectedTab === 'active' && (
            <>
              <Column
                title="Seniority"
                dataIndex="seniorityType"
                key="seniorityType"
                render={data => <div>{data ? data : '-'}</div>}
              />

              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={data => (
                  <div
                    style={{
                      display: 'flex',
                      width: 'fit-content',
                      padding: '10px',
                      backgroundColor: data === 9 ? '#ff7979' : 'lightgreen',
                      border: data === 9 ? '2px solid #fe0000' : '2px solid #48d43c',
                      borderRadius: '50px',
                    }}
                  >
                    {returnQuestionStatusBasedOnId(data)}
                  </div>
                )}
              />
            </>
          )}
          {selectedTab === 'new' && (
            <>
              <Column
                title="Client Approval"
                align="center"
                dataIndex="rejectionStatus"
                key="rejectionStatus"
                render={data => {
                  const temp = getClientRejectionObject(data)?.filter(e => e);
                  return data?.length ? (
                    data[data.length - 1]?.reject_by_client === 1 ? (
                      <div className="d-flex-a-c">
                        <Tooltip
                          placement="left"
                          title={() => {
                            return (
                              <li className="p-3">
                                <p style={{ textDecoration: 'underline' }}>Reason for rejection</p>
                                {temp[temp.length - 1]?.reasons_for_rejection}
                              </li>
                            );
                          }}
                        >
                          <img height={20} width={20} src={wrongIcon} alt="" />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="d-flex-a-c">
                        <img height={20} width={20} src={checkIcon} alt="" />
                      </div>
                    )
                  ) : (
                    <div className="d-flex-a-c">
                      <img height={20} width={20} src={exclamationIcon} alt="" />
                    </div>
                  );
                }}
              />
              <Column
                title="Expert Approval"
                align="center"
                dataIndex="rejectionStatus"
                key="rejectionStatus"
                render={data => {
                  const temp = getExpertRejectionObject(data)?.filter(e => e);
                  return data?.length ? (
                    data[data?.length - 1]?.reject_by_expert === 1 ? (
                      <div className="d-flex-a-c">
                        <Tooltip
                          placement="left"
                          title={() => {
                            return (
                              <li className="p-3">
                                <p style={{ textDecoration: 'underline' }}>Reason for rejection</p>
                                {temp[temp.length - 1]?.reasons_for_rejection}
                              </li>
                            );
                          }}
                        >
                          <img height={20} width={20} src={wrongIcon} alt="" />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="d-flex-a-c">
                        <img height={20} width={20} src={checkIcon} alt="" />
                      </div>
                    )
                  ) : (
                    <div className="d-flex-a-c">
                      <img height={20} width={20} src={exclamationIcon} alt="" />
                    </div>
                  );
                }}
              />
            </>
          )}
          
          <Column
            title="Detail"
            align="center"
            dataIndex="id"
            key="id"
            render={data => {
              return (
                <div
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedRequestId(data);
                    setTimeout(() => {
                      setShowRequestDetailModal(true);
                    }, 100);
                  }}
                >
                  View
                </div>
              );
            }}
          />
          <Column
            title="Action"
            align="center"
            key="action"
            render={data => (
              <Space size="middle">
                <Popover placement="left" content={() => content(data)} trigger="hover">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className="table-action-column-wrapper"
                  >
                    <BiDotsVerticalRounded
                      style={{ rotate: '90deg' }}
                      size={18}
                      className="cursor-p"
                    />
                  </div>
                </Popover>
              </Space>
            )}
          />
        </Table>
      </div>
      <DeleteModal
        visible={showDeleteModal}
        setVisible={() => {
          setCurrentRecord({});
          setShowDeleteModal(false);
        }}
        subTitle="You want to delete this request"
        deleteModule="request"
        rowData={currentRecord}
        doRefresh={() => doRefresh()}
      />

      <AssignQuestionToExpertModal
        visible={showAssignModal}
        setVisible={setShowAssignModal}
        rowData={currentRecord}
        rawData={rawData}
        doRefresh={() => doRefresh()}
        variant="admin"
      />

      <QuestionDetailModal
        title={modalTitle}
        visible={showRequestDetailModal}
        setVisible={setShowRequestDetailModal}
        questionId={selectedRequestId}
      />
    </div>
  );
};

export default AdminRequests;
