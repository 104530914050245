import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatDateBlog } from '../../helpers/functions';
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';

const BlogBox = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const blogFromRedux = useSelector(state => state?.common?.singleBlog);
  const isBlogLoading = useSelector(state => state?.common?.isBlogLoading);
  const [blog, setBlog] = useState({});
  console.log(blog);
  useEffect(() => {
    if (location.state) {
      dispatch({ type: 'GET_SINGLE_BLOG', id: location.state });
    }
  }, [location.state]);
  useEffect(() => {
    setBlog(blogFromRedux);
  }, [blogFromRedux]);

  return (
    <>
      {/* <button onClick={() => window.history.go(-1)}>
    back
  </button> */}
      <Row style={{ minHeight: '100vh', backgroundColor: 'rgba(255,255,255)', padding: '5rem' }}>
        {Object.keys(blog).length > 0 ? (
          <>
            <Col
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  width: '80%',
                  objectFit: 'cover',
                  position: 'sticky',
                  top: '0',
                  boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 10px',
                }}
                src={blog?.featured_image}
                // src={`https://dummyimage.com/200x200/d4d4d4/454545&text=Deepanshu`}
                alt="Blog Image"
              />
            </Col>{' '}
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              <h1 style={{ color: 'black' }}>{blog?.title}</h1>
              <p style={{ marginBottom: '3rem' }}>{formatDateBlog(blog?.created_at)}</p>
              <p
                style={{ textAlign: 'start', lineHeight: '2rem', fontSize: '1rem' }}
                dangerouslySetInnerHTML={{ __html: blog?.body }}
              ></p>
              {/* <p style={{ textAlign: 'start', lineHeight: '1.5rem', fontSize: '1rem' }}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque totam maxime eligendi
              veniam expedita aliquam, nihil amet laboriosam facilis earum rem, cum quis molestias.
              Debitis, necessitatibus asperiores dicta aspernatur aliquam, officia minus atque
              consequuntur officiis sequi alias consequatur quam est enim quia laudantium cum dolore
              iusto minima iure doloremque neque doloribus nisi ipsum. Vel, obcaecati libero, ut
              laborum iste rerum minus fugiat corporis veniam consequuntur atque nisi quam accusamus
              voluptatum qui eos dolorum architecto animi! Veritatis tenetur, quidem doloremque
              labore recusandae esse corrupti error quod vitae totam exercitationem odit modi
              laboriosam cumque quae quia quaerat illum commodi iure vel? Deserunt corporis corrupti
              atque debitis harum sunt dolor exercitationem recusandae, eaque quas eum magnam iure
              laboriosam aperiam in, facere cum qui minima libero non est ipsum culpa fugit! Maxime
              magni deserunt qui officiis corporis, exercitationem autem consectetur tempore
              dolorem. Atque deserunt at rerum molestias voluptates amet perferendis quae fugit
              corrupti est esse nam iste, dignissimos aperiam distinctio sint enim delectus magni
              libero a ipsam quibusdam nostrum! Nemo eum tempore aut voluptatibus id animi. Facilis,
              at repudiandae deserunt illum maxime pariatur itaque necessitatibus sunt laborum iste
              ad eveniet porro veniam, asperiores quaerat natus? Distinctio eaque, doloremque
              aperiam eius delectus iste doloribus laudantium fugiat soluta eos ipsam dolore
              provident voluptate vitae laborum, sunt optio vel! Eos ut voluptates tenetur suscipit,
              officiis ullam qui nemo facere dolore omnis magni fugiat laudantium? Provident, cum
              voluptatum commodi iste molestias corporis est debitis architecto quidem! Repellendus,
              delectus quia quasi inventore optio officiis dignissimos eveniet cumque nihil
              eligendi, vitae nisi ipsam odit dolores facere molestiae unde modi voluptatum tempore
              natus et. Eum perferendis cupiditate culpa perspiciatis blanditiis optio nostrum nisi
              tenetur repellendus! Aperiam porro tenetur ea ipsam non quas dicta dignissimos vitae
              aspernatur. Adipisci recusandae, ipsam earum cumque eaque temporibus saepe nemo
              dolorum nesciunt veniam numquam necessitatibus modi illo amet voluptatum. Illum,
              exercitationem inventore blanditiis, iure accusamus consequuntur quam distinctio enim
              dolore non veniam in repudiandae. Harum, tempora. Aperiam tempora adipisci modi error
              iusto ipsam illum natus aut odio! Dolorem iure accusantium animi voluptatibus unde
              necessitatibus dolorum corporis laudantium, non consectetur esse soluta ea harum
              dolores exercitationem nam ab incidunt accusamus. Est porro odio, temporibus
              distinctio modi in placeat corporis? Hic rerum quo ipsa doloribus possimus eius
              consequuntur iure, corrupti repellendus assumenda beatae neque minus at voluptate
              nostrum, earum excepturi dolorum vitae sit exercitationem dolorem debitis illum quis!
              Dicta at, officia esse, eum eveniet, sunt libero repellat similique a molestiae vitae
              iure commodi suscipit animi numquam maxime soluta unde omnis culpa sint possimus
              totam? Harum pariatur maiores quasi adipisci sint? Accusantium, autem aliquid
              laudantium tempora error debitis, temporibus quae ipsum eaque excepturi beatae
              voluptate possimus quasi tenetur quas aliquam doloribus mollitia, ab consequuntur.
              Accusantium est similique illum beatae asperiores, ipsam omnis cupiditate animi?
              Aliquid illum omnis, magnam blanditiis deleniti, dolores quam quae ipsum quaerat ab
              adipisci in minus impedit, quasi velit! Alias incidunt, placeat quo consequatur rerum
              officiis voluptatem adipisci, excepturi quisquam tempora id facilis nulla iure? Sint
              explicabo odit cupiditate voluptas quia nihil exercitationem eos laudantium porro
              veritatis magnam, at dolores dolore vel animi natus maxime aut repellendus voluptatem
              obcaecati doloribus? Libero nemo tenetur sit possimus dicta. Dignissimos accusamus
              suscipit enim, corrupti, molestias alias reiciendis unde architecto obcaecati totam,
              doloribus quod distinctio excepturi sequi asperiores placeat in vel nobis error
              veritatis dicta. Ducimus, at placeat? Commodi ad ipsa necessitatibus, dolore eaque rem
              omnis placeat nulla iusto mollitia enim adipisci distinctio consectetur assumenda odit
              accusamus labore sint! Quae tempore debitis ullam quod ad, architecto maiores nulla
              sit praesentium unde rem exercitationem vitae provident enim minus excepturi vero
              iusto totam quo vel. Quod cupiditate iure consequatur, reprehenderit vero atque aut
              minus quasi animi eligendi adipisci veritatis eius qui labore obcaecati eum magnam,
              incidunt sequi, nobis necessitatibus! Odio nostrum tenetur natus blanditiis doloremque
              ducimus. Assumenda iusto ipsum, quidem suscipit incidunt fugit impedit sed facere
              officiis quos iste labore. Expedita amet quas, cumque error cum, natus voluptatem id
              cupiditate corporis aspernatur totam repellat fugiat! Adipisci eos architecto fugit
              doloribus praesentium nisi vero voluptatibus facere enim. Ab, necessitatibus, nam
              expedita id voluptate natus vitae, error dolorum iste voluptatum itaque laborum non?
              Delectus alias, repellendus, error sed in enim omnis eaque, qui possimus esse deserunt
              ipsum ad laboriosam? Vero ad magni dolore earum iure illo culpa, quia voluptas,
              reprehenderit temporibus inventore iusto odio, incidunt tempore molestiae repellat
              exercitationem quae distinctio ullam dolorum? Et suscipit, illum beatae in accusantium
              nulla temporibus? Aliquam, labore enim quibusdam quod assumenda placeat eum nihil
              cumque quam nulla porro natus tempore doloribus sequi incidunt odit iste totam cum
              repellat ipsa iure. Explicabo, eveniet voluptates? Quis quod ratione reprehenderit
              mollitia esse quam, neque, explicabo nobis totam quo eius architecto, hic porro.
              Incidunt dolores dignissimos facere, eius explicabo sint libero dolore dolorum
              doloremque cumque sapiente officiis cum maxime porro quisquam voluptatum
              necessitatibus molestias alias! Animi, at soluta eius autem nemo unde ducimus
              veritatis, alias iste facilis dicta numquam, blanditiis rerum! Corrupti vel
              praesentium sint, mollitia nam officiis harum a sed! Dolores expedita at, voluptatem
              veritatis illo omnis natus eum inventore quaerat iure veniam. Animi, dolore
              accusantium! Dignissimos excepturi nobis eligendi dolorem, eveniet minus qui corrupti.
              Rem corrupti earum repellat voluptatem doloribus quia aliquam! Quaerat, laudantium!
              Libero laudantium ab, assumenda quis animi adipisci? Atque aliquid accusamus
              repellendus quas laborum! Suscipit quae temporibus repellat sunt id esse rerum
              consectetur quisquam ex perspiciatis quo rem a, adipisci corrupti at ullam est
              molestias fugit. Error beatae quidem voluptatum quibusdam, vel pariatur magni iure
              molestias culpa dolores! Nisi reiciendis temporibus ratione alias impedit non, ad
              mollitia perspiciatis, provident minus aliquam quas praesentium quibusdam quae quis
              repudiandae earum enim aperiam voluptatum vero assumenda accusamus asperiores
              voluptatibus ipsam? Omnis quas fuga commodi, veniam odio quidem repellat! Quis
              aspernatur asperiores alias deserunt molestiae, eos blanditiis excepturi quae quasi
              est dolor atque aliquam officiis consequuntur. Illo repudiandae, accusamus quod facere
              suscipit alias, accusantium iste officia possimus, quos odio quia ad qui iusto ab
              repellendus beatae incidunt quibusdam nulla magnam rerum amet tempore? Atque, cum.
              Quae doloremque quia, id repudiandae distinctio dicta, error, quam autem repellat
              ullam aliquid beatae facilis vitae.
            </p> */}
              <div style={{ display: 'flex', justifyContent: 'space-between',width: '100%' }}>
                <div className='d-flex-a-c'>
                  <p style={{ marginTop: '1rem', color: 'rgb(74,127,234)' }}>Share This: </p>
                  <span className="mr10 ml20">
                    <AiOutlineTwitter className="cursor-p" size={22} color="#6B7280" />
                  </span>
                  <span className="mr10">
                    <RiFacebookFill className="cursor-p" size={22} color="#6B7280" />
                  </span>
                  <span>
                    <AiFillLinkedin className="cursor-p" size={22} color="#6B7280" />
                  </span>
                </div>
                <div className='d-flex-a-c'>
                  {
                    !!blog?.tags?.length && blog?.tags?.map((tag, index) => (
                      <span className='tag mr10' key={index}>#{tag?.name}</span>
                      
                    ))
                  }
                </div>
              </div>
              
            </Col>
          </>
        ) : (
          isBlogLoading && <Spin />
        )}
      </Row>
    </>
  );
};

export default BlogBox;
