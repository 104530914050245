import React from 'react';
import { Card, Col, Divider, Tooltip } from 'antd';
import img1 from '../../assets/images/expert/icon1.svg';
import img2 from '../../assets/images/expert/icon2.svg';
import img3 from '../../assets/images/expert/icon3.svg';
import starLogo from '../../assets/images/star.svg';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { breakName } from '../../helpers/functions';
import { nameRecommendationConstantForToolTip } from '../../helpers/constant';
const ExpertCard = ({ item, onClick }) => {
  const displayLang = data =>
    data.length
      ? data.map((e, idx) => {
        return idx < 2 ? (
          <span id={idx}>
            {data.length === idx + 1
              ? e.name
              : `${e.name}${idx !== 1 ? ', ' : ` +${data.length - 2}`}`}
          </span>
        ) : null;
      })
      : '-';

  const displayQualification = (data, nameProperty) => {
    const totalCount = data.length;
    if (totalCount) {
      const ellispedName =
        data[0][nameProperty].length > 45
          ? data[0][nameProperty].slice(0, 45) + '...'
          : data[0][nameProperty];
      return (
        <span id={0}>
          {ellispedName}
          {totalCount > 1 ? <sup>+{totalCount - 1}</sup> : null}
        </span>
      );
    } else {
      return '-';
    }
  };

  const displayCategory = data =>
    data.length
      ? data
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (value, index, self) =>
            index ===
            self?.findIndex(prev => prev.name === value.name && prev.name === value.name),
        )
        .map((e, idx, self) => {
          return idx < 1 ? (
            <p className="mb-0 cat-title" id={idx}>
              {self.length === idx + 1
                ? e.name
                : `${e.name}${idx === 1 ? ', ' : ` +${self.length - 1}`}`}
            </p>
          ) : null;
        })
      : '-';

  const displaySubCategory = data =>
    data.length
      ? data
        .filter(
          (value, index, self) =>
            index ===
            self?.findIndex(
              prev =>
                prev.industry?.name === value.industry?.name &&
                prev.industry?.name === value.industry?.name,
            ),
        )
        .map((e, idx, self) => {
          return idx < 1 ? (
            <span id={idx}>
              {self.length === idx + 1
                ? e.industry.name.length > 30
                  ? e.industry.name.slice(0, 30) + '...'
                  : e.industry.name
                : `${e.industry.name.length > 40
                  ? e.industry.name.slice(0, 40) + '...'
                  : e.industry.name
                }${idx === 1 ? ', ' : ` +${self.length - 1}`}`}
            </span>
          ) : null;
        })
      : '-';
  return (
    <Col xs={24} sm={24} md={8} lg={8} xl={8} key={item.id}>
      <Card className="card-parent" onClick={onClick}>
        <div>
          <div style={{ position: 'absolute', right: '30px', top: '30px' }}>
            <div
              style={{ backgroundColor: '#f1f1f1', color: 'black' }}
              className="profile-expert-rating-container"
            >
              <img src={starLogo} alt="" />
              <div>{item?.rating}</div>
            </div>
          </div>
          <img alt="expert" className="main-img" src={item?.avatar ? item?.avatar : expertIcon} />
          <div className="card-section mt-5">

            <p className="mb-0 title">
              <Tooltip title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                {breakName(item?.first_name, item?.last_name)}
              </Tooltip>

            </p>


            {item.category.length ? displayCategory(item.category) : '-'}
            <Divider className="mt10 mb10" />
            <div className="expert-info-wrapper">
              <div className="card-section2">
                <img src={img1} alt="make"></img>
                <p className="mb-0 cat-title">
                  {item?.certificates?.length
                    ? displayQualification(item.certificates, 'designation')
                    : '-'}
                  {item?.degrees?.length ? ', ' : ''}
                  {item?.degrees?.length ? displayQualification(item.degrees, 'name') : ''}
                </p>
              </div>
              <div className="card-section2">
                <img src={img2} alt="make"></img>
                <p>
                  {item.business_sector.length ? displaySubCategory(item.business_sector) : '-'}
                </p>
              </div>
              <div className="card-section2">
                <img src={img3} alt="make"></img>

                <p className="mb-0 cat-title">
                  {item.languages.length ? displayLang(item.languages) : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ExpertCard;
